import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
};

export type Awss3UploadUrlAndKey = {
  __typename?: 'AWSS3UploadUrlAndKey';
  awsS3Key: Scalars['String']['output'];
  uploadHeaders: FileUploadHeaders;
  uploadUrl: Scalars['String']['output'];
};

export type AbandonRecruitmentTaskInput = {
  recruitmentTaskId: Scalars['String']['input'];
};

export type AcademicBackground = {
  __typename?: 'AcademicBackground';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AcceptInvitationInput = {
  /** The id of the invitation */
  id: Scalars['ID']['input'];
};

export type AcceptJobApplicationForFirstInterviewInput = {
  jobApplicationId: Scalars['String']['input'];
  messageForApplicant?: InputMaybe<Scalars['String']['input']>;
};

export type ActivitySector = {
  __typename?: 'ActivitySector';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AddPotentialJobCategoryInput = {
  name: Scalars['String']['input'];
};

export type AddProfilePotentialSkillInput = {
  potentialSkillName: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type AddProfileToCvBankInput = {
  profileId: Scalars['String']['input'];
};

export type Address = {
  __typename?: 'Address';
  country?: Maybe<Country>;
  id: Scalars['ID']['output'];
  locality: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
};

export type AddressInput = {
  countryId: Scalars['String']['input'];
  locality: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
};

export type AgreeToTermsOfServiceInput = {
  version: Scalars['Float']['input'];
};

export enum AlertStatus {
  Deleted = 'DELETED',
  Disabled = 'DISABLED',
  Enabled = 'ENABLED',
}

export type AnonymizedExperience = {
  __typename?: 'AnonymizedExperience';
  employer: ExperienceEmployer;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  skills?: Maybe<Array<Skill>>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnonymizedProfile = {
  __typename?: 'AnonymizedProfile';
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  creator?: Maybe<User>;
  cvBankDescription?: Maybe<CvBankProfileDescription>;
  educations: Array<Education>;
  experiences: Array<AnonymizedExperience>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  preferredRemoteWorkPolicy?: Maybe<RemoteWorkPolicy>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  salaryClaimAmount?: Maybe<Scalars['Int']['output']>;
  salaryClaimCurrencyCode?: Maybe<CurrencyIso4217Code>;
  urbanArea?: Maybe<UrbanArea>;
  validSkills: Array<Skill>;
};

export type AnonymizedProfileOffsetBasedConnection = {
  __typename?: 'AnonymizedProfileOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<AnonymizedProfile>;
  totalCount: Scalars['Int']['output'];
};

export type AnonymizedProfilesFilters = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['String']['input']>>;
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  schoolIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  skills?: InputMaybe<SkillsWithQueryModeInput>;
  urbanArea?: InputMaybe<UrbanAreaWithRadiusInput>;
};

export type AttributeCanonicalProfileToRecruiterInput = {
  linkedInVanityName: Scalars['String']['input'];
  recruiterId: Scalars['String']['input'];
};

export type BankDetails = {
  __typename?: 'BankDetails';
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type BankDetailsInput = {
  iban?: InputMaybe<Scalars['String']['input']>;
};

export type BookVideoBriefMeetingInput = {
  endDate: Scalars['DateTime']['input'];
  jobOfferId: Scalars['ID']['input'];
  startDate: Scalars['DateTime']['input'];
};

export enum BusinessType {
  Employer = 'EMPLOYER',
  SearchFirm = 'SEARCH_FIRM',
}

export type CvBankAlert = {
  __typename?: 'CVBankAlert';
  cvBankSearch: CvBankSearch;
  id: Scalars['ID']['output'];
  status: AlertStatus;
};

export type CvBankProfile = {
  __typename?: 'CVBankProfile';
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  educations: Array<Education>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  email?: Maybe<Scalars['String']['output']>;
  /** The profile's experiences list, in an descending order. Will always return an empty array if the viewer ran out of cv bank credit and the profile has not been discovered yet. */
  experiences: Array<Experience>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobCategories: Array<JobCategory>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Will always return null if the viewer has not been discovered the profile yet. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  salaryClaimAmount?: Maybe<Scalars['Int']['output']>;
  salaryClaimCurrencyCode?: Maybe<CurrencyIso4217Code>;
  urbanArea?: Maybe<UrbanArea>;
};

export type CvBankProfileDescription = {
  __typename?: 'CVBankProfileDescription';
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CvBankProfileDiscoverabilityPeriod = {
  __typename?: 'CVBankProfileDiscoverabilityPeriod';
  addedAt: Scalars['DateTime']['output'];
  removedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CvBankProfileOffsetBasedConnection = {
  __typename?: 'CVBankProfileOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<CvBankProfile>;
  totalCount: Scalars['Int']['output'];
};

export type CvBankProfilesFilters = {
  isAlreadyViewed?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenToWork?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, jobCategoryIds filter should correspond to a list of deprecatedJobCategoryIds */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  urbanAreaId?: InputMaybe<Scalars['String']['input']>;
};

export type CvBankSearch = {
  __typename?: 'CVBankSearch';
  id: Scalars['ID']['output'];
  isOpenToWork?: Maybe<Scalars['Boolean']['output']>;
  jobCategories: Array<JobCategory>;
  minNumberOfYearsOfExperience?: Maybe<Scalars['Float']['output']>;
  skills: Array<Skill>;
  urbanArea?: Maybe<UrbanArea>;
};

export type CvBankViewCountViewerPair = {
  __typename?: 'CVBankViewCountViewerPair';
  firstViewAt: Scalars['DateTime']['output'];
  lastViewAt: Scalars['DateTime']['output'];
  viewCount: Scalars['Int']['output'];
  viewer: User;
};

export type CvBankViewCountViewerPairOffsetBasedConnection = {
  __typename?: 'CVBankViewCountViewerPairOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<CvBankViewCountViewerPair>;
  totalCount: Scalars['Int']['output'];
};

export type CarbonCopyCouple = {
  __typename?: 'CarbonCopyCouple';
  carbonCopyUserId: Scalars['String']['output'];
  creatorId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  topic: Scalars['String']['output'];
};

export enum CarbonCopyTopic {
  JobApplication = 'JOB_APPLICATION',
}

export type CareerData = {
  __typename?: 'CareerData';
  academicBackgrounds: Array<AcademicBackground>;
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  deprecatedJobCategories: Array<JobCategory>;
  /**
   * For now, this field returns the deprecatedJobCategories
   * @deprecated use deprecatedJobCategories and newJobCategories instead
   */
  jobCategories: Array<JobCategory>;
  /** This field returns the new jobCategories */
  newJobCategories: Array<JobCategory>;
  skills: Array<Skill>;
};

export type ChatChannel = {
  __typename?: 'ChatChannel';
  id: Scalars['ID']['output'];
  lastMessage?: Maybe<Message>;
  users: Array<User>;
};

export type ChatChannelOffsetBasedConnection = {
  __typename?: 'ChatChannelOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<ChatChannel>;
  totalCount: Scalars['Int']['output'];
};

export type ChatChannelsFilters = {
  searchedText?: InputMaybe<Scalars['String']['input']>;
};

export type CheckIfSearchFirmNameIsAvailableInput = {
  name: Scalars['String']['input'];
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  url: Scalars['String']['output'];
};

export type ClassificationJob = {
  __typename?: 'ClassificationJob';
  jobId: Scalars['ID']['output'];
  startedAt: Scalars['DateTime']['output'];
};

export type ClassifyAndCreateJobOfferInput = {
  /** For now, this needs to be used in combination with "fromText" parameters since the user can modify the original text provided by the jobOfferLead */
  fromJobOfferLead?: InputMaybe<Scalars['ID']['input']>;
  fromText: Scalars['String']['input'];
  rawTitle: Scalars['String']['input'];
};

export type CompanyLogoUploadUrlAndFileNameInput = {
  companyName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
};

export type CompleteJobOfferCreationFormInput = {
  jobOfferId: Scalars['String']['input'];
};

export type ComputeProfileJobApplicationRecommendationsInput = {
  profileId: Scalars['String']['input'];
};

export type ComputeProfileJobOfferPotentialMatchesInput = {
  profileId: Scalars['String']['input'];
};

export type Contract = {
  __typename?: 'Contract';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /**
   * The list of statuses of the contract.
   * The most recent status is the current status of the contract.
   *
   */
  statuses?: Maybe<Array<ContractStatus>>;
};

export type ContractStatus = {
  __typename?: 'ContractStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: ContractStatusName;
};

export enum ContractStatusName {
  PendingSignature = 'PENDING_SIGNATURE',
  Signed = 'SIGNED',
}

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CreateEmployerMessageTemplateInput = {
  body: Scalars['String']['input'];
  context: MessageTemplateContext;
  title: Scalars['String']['input'];
};

export type CreateHireGaranteeCheckoutSessionInput = {
  garanteeDuration: HireGaranteeDuration;
  hiredApplicantFirstName: Scalars['String']['input'];
  hiredApplicantJobStartDate: Scalars['DateTime']['input'];
  hiredApplicantLastName: Scalars['String']['input'];
  /** This is the monthly price as mentioned on the front-end. It will be double-checked by the back-end */
  monthlyPriceInCents: Scalars['Int']['input'];
};

export type CreateInvitationInput = {
  email: Scalars['String']['input'];
};

export type CreateInvoiceInput = {
  dueDate: Scalars['DateTime']['input'];
  externalClientId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  invoicingProviderName: InvoicingProviderName;
  issueDate: Scalars['DateTime']['input'];
  items: Array<InvoiceItemInput>;
  purchaseOrderId?: InputMaybe<Scalars['String']['input']>;
  status: InvoiceStatus;
};

export type CreateMyEmployerInput = {
  name: Scalars['String']['input'];
};

export type CreateProfileInput = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['String']['input']>>;
  approximateFirstJobStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeAwsS3Key?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeFilename?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName: Scalars['String']['input'];
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryIds. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName: Scalars['String']['input'];
  linkedInProfileUrl?: InputMaybe<Scalars['String']['input']>;
  /** For now, use this newJobCategoryIds input to create the profile's new jobCategories. Delete this field as soon as the input jobCategoryIds creates the new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  origin?: InputMaybe<ProfileCreationOrigin>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  potentialSkillNames?: InputMaybe<Array<Scalars['String']['input']>>;
  profilePictureFilename?: InputMaybe<Scalars['String']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateSearchFirmInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  legalForm?: InputMaybe<LegalForm>;
  name: Scalars['String']['input'];
  numberOfRecruiters?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateStripeCustomerCheckoutSessionInput = {
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  priceId: Scalars['ID']['input'];
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateUserInput = {
  firstName: Scalars['String']['input'];
  invitationId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  mainBusinessType: BusinessType;
  phoneNumber: Scalars['String']['input'];
};

export type CreateUserLeadInput = {
  mainBusinessType: BusinessType;
};

export enum CurrencyIso4217Code {
  Cad = 'CAD',
  Chf = 'CHF',
  Eur = 'EUR',
  Gbp = 'GBP',
  Mad = 'MAD',
  Tnd = 'TND',
  Usd = 'USD',
}

export type CurriculumVitaeAwss3UploadUrlAndKeyInput = {
  fileType: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type DeclareApplicantHiredByMyCompanyInput = {
  grossAnnualFixedRemunerationInCents?: InputMaybe<Scalars['Int']['input']>;
  grossAnnualVariableRemunerationInCents?: InputMaybe<Scalars['Int']['input']>;
  jobApplicationId: Scalars['ID']['input'];
  jobStartDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DeleteCvBankAlertInput = {
  alertId: Scalars['ID']['input'];
};

export type DeleteEmployerMessageTemplateInput = {
  messageTemplateId: Scalars['ID']['input'];
};

export type DeprecatedJobCategoriesFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobCategoryStatus>>;
};

export type EditProfilePersonalNoteInput = {
  body: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type Education = {
  __typename?: 'Education';
  degreeTitle?: Maybe<Scalars['String']['output']>;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  school: School;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Employer = {
  __typename?: 'Employer';
  address?: Maybe<Address>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nonPoachingAgreementsDescription?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
};

export type EmployerJobOfferLeadsFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type EmployerJobOffersFilters = {
  isTerminated?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum EntitiesQueryMode {
  Exhaustive = 'EXHAUSTIVE',
  NonExhaustive = 'NON_EXHAUSTIVE',
}

export type Experience = {
  __typename?: 'Experience';
  description?: Maybe<Scalars['String']['output']>;
  employer: ExperienceEmployer;
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  skills?: Maybe<Array<Skill>>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** We decided not to use the same Employer entity, reasons are explained here https://www.notion.so/How-to-store-information-about-employer-in-Profile-experiences-de0ad43a0b60450782e94daedf4949eb?pvs=4 */
export type ExperienceEmployer = {
  __typename?: 'ExperienceEmployer';
  linkedInCompanyId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ExpressInterestInProfileInput = {
  jobOfferId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type ExpressInterestInProfilesInput = {
  jobOfferId: Scalars['String']['input'];
  profileIds: Array<Scalars['String']['input']>;
};

export type ExtensionConfig = {
  __typename?: 'ExtensionConfig';
  /** a JSON config containing selectors to extract profile informations on a LinkedIn page. See more on muzzo-extension */
  profileSelectors: Scalars['String']['output'];
};

export type ExtractJobOfferSkillsInput = {
  jobOfferId: Scalars['String']['input'];
};

export type ExtractJobOffersSkillsFilters = {
  ongoingOnly: Scalars['Boolean']['input'];
  withJobApplicationAcceptedForInterview?: InputMaybe<
    Scalars['Boolean']['input']
  >;
};

export type FileUploadHeaders = {
  __typename?: 'FileUploadHeaders';
  contentDisposition: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
};

export type GenerateSearchFirmContractInput = {
  searchFirmLegalRepresentativeContact: SearchFirmLegalRepresentativeContactInput;
};

export type GetCareerDataFromLinkedInInput = {
  educations?: InputMaybe<Array<LinkedInEducationInput>>;
  experiences?: InputMaybe<Array<LinkedInExperienceInput>>;
  headline?: InputMaybe<Scalars['String']['input']>;
};

export type GetEmployersInput = {
  searchText: Scalars['String']['input'];
};

export type GetJobApplicationRecommendationInput = {
  jobApplicationRecommendationId: Scalars['String']['input'];
};

export type GetJobOfferNewsFeedInput = {
  jobOfferId: Scalars['String']['input'];
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type GetLastProfileJobApplicationRecommendationsComputationInput = {
  profileId: Scalars['String']['input'];
};

export type GetMessagesInput = {
  chatChannelId: Scalars['String']['input'];
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type GetMyChatChannelsInput = {
  filters?: InputMaybe<ChatChannelsFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type GetProfileAcademicBackgroundsInput = {
  profileId: Scalars['String']['input'];
};

export type GetProfileInput = {
  profileId: Scalars['String']['input'];
};

export type GetProfileJobApplicationRecommendationRankingInput = {
  profileId: Scalars['String']['input'];
};

export type GetProfileJobApplicationRecommendationsComputationInput = {
  computationId: Scalars['String']['input'];
  profileId: Scalars['String']['input'];
};

export type GetProfileJobOfferPotentialMatchesInput = {
  profileId: Scalars['String']['input'];
};

export type GetSchoolsInput = {
  searchedText: Scalars['String']['input'];
};

export type GetSkillsInput = {
  search: Scalars['String']['input'];
};

export type GetValidSkillsInput = {
  search: Scalars['String']['input'];
};

export type HasAlreadyAppliedToJobOfferInput = {
  jobOfferId: Scalars['String']['input'];
  profileIdentificationData: ProfileIdentificationDataInput;
};

export enum HireGaranteeDuration {
  SixMonths = 'SIX_MONTHS',
  TwelveMonths = 'TWELVE_MONTHS',
}

export type HiringManagerLikesAnonymizedProfile = {
  __typename?: 'HiringManagerLikesAnonymizedProfile';
  anonymizedProfile: AnonymizedProfile;
  createdAt: Scalars['DateTime']['output'];
  hiringManager: User;
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
};

export type InternalManager = {
  __typename?: 'InternalManager';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  myOneToOneChatChannelId?: Maybe<Scalars['String']['output']>;
  phoneNumber: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
};

export type Invitation = {
  __typename?: 'Invitation';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  businessType: BusinessType;
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  email: Scalars['String']['output'];
  employer?: Maybe<Employer>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  searchFirm?: Maybe<SearchFirm>;
  status: InvitationStatus;
};

export enum InvitationStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
}

export type InvitationsFilters = {
  onlyPending?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID']['output'];
};

export type InvoiceItemInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  price: PriceInput;
  quantityInHundredths: Scalars['Int']['input'];
  title: Scalars['String']['input'];
  vatRateInBasisPoints: Scalars['Int']['input'];
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
}

export enum InvoicingProviderName {
  Qonto = 'QONTO',
}

export type JobApplication = {
  __typename?: 'JobApplication';
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  profile: Profile;
  recruitmentTask: RecruitmentTask;
  statuses?: Maybe<Array<JobApplicationStatus>>;
};

export type JobApplicationOffsetBasedConnection = {
  __typename?: 'JobApplicationOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<JobApplication>;
  totalCount: Scalars['Int']['output'];
};

export type JobApplicationRecommendation = {
  __typename?: 'JobApplicationRecommendation';
  createdAt: Scalars['DateTime']['output'];
  hasApplied: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  jobOfferMatchingCriteria?: Maybe<Array<JobOfferMatchingCriterion>>;
  profile: Profile;
  recruiterFlatFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruiterSuccessFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruitmentTask?: Maybe<RecruitmentTask>;
  score?: Maybe<Scalars['Int']['output']>;
  status: JobApplicationRecommendationStatus;
};

export enum JobApplicationRecommendationStatus {
  Current = 'current',
  Obsolete = 'obsolete',
}

export type JobApplicationStatus = {
  __typename?: 'JobApplicationStatus';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jobApplication: JobApplication;
  message?: Maybe<Message>;
  name: JobApplicationStatusName;
};

export enum JobApplicationStatusName {
  AcceptedForFirstInterview = 'ACCEPTED_FOR_FIRST_INTERVIEW',
  ExpiredBecauseJobOfferHasBeenClosed = 'EXPIRED_BECAUSE_JOB_OFFER_HAS_BEEN_CLOSED',
  Hired = 'HIRED',
  Rejected = 'REJECTED',
  SuggestedByRecruiter = 'SUGGESTED_BY_RECRUITER',
}

export type JobApplicationsFilters = {
  jobOfferId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobApplicationStatusName>>;
};

export type JobCategoriesFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobCategoryStatus>>;
};

export type JobCategory = {
  __typename?: 'JobCategory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status?: Maybe<JobCategoryStatus>;
};

export enum JobCategoryStatus {
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Valid = 'VALID',
}

export type JobOffer = {
  __typename?: 'JobOffer';
  academicBackgrounds?: Maybe<Array<AcademicBackground>>;
  briefingVideoUrl?: Maybe<Scalars['String']['output']>;
  careerOutlook?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<User>;
  currencyCode?: Maybe<CurrencyIso4217Code>;
  deprecatedJobCategory?: Maybe<JobCategory>;
  description: Scalars['String']['output'];
  employer?: Maybe<Employer>;
  flatFee?: Maybe<Scalars['Int']['output']>;
  hasHealthInsurancePlan?: Maybe<Scalars['Boolean']['output']>;
  hasIncentiveBonus?: Maybe<Scalars['Boolean']['output']>;
  hasLuncheonVouchers?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  internalManager?: Maybe<InternalManager>;
  isTeleworkingAuthorized?: Maybe<Scalars['Boolean']['output']>;
  isTerminated: Scalars['Boolean']['output'];
  /**
   * Fow now, this field corresponds to the jobOffer deprecatedJobCategory
   * @deprecated Use deprecatedJobCategory or newJobCategory instead. Update the website, wait a few days, then update this field to return the new jobCategory
   */
  jobCategory?: Maybe<JobCategory>;
  latestSuccessFee?: Maybe<SuccessFee>;
  likedAnonymizedProfiles: Array<HiringManagerLikesAnonymizedProfile>;
  location?: Maybe<Scalars['String']['output']>;
  mandatoryProfileAttributes?: Maybe<Scalars['String']['output']>;
  maxNumberOfYearsOfExperience?: Maybe<Scalars['Int']['output']>;
  maximumRemuneration?: Maybe<Scalars['Int']['output']>;
  maximumVariableRemuneration?: Maybe<Scalars['Int']['output']>;
  minNumberOfYearsOfExperience?: Maybe<Scalars['Int']['output']>;
  minimumRemuneration?: Maybe<Scalars['Int']['output']>;
  motivationalMessageToRecruiters?: Maybe<Scalars['String']['output']>;
  /** Fow now, use this field to get the jobOffer new jobCategory. Delete this field as soon as the field jobCategory returns the new jobCategory */
  newJobCategory?: Maybe<JobCategory>;
  notificationSubscriptions: Array<JobOfferNotificationSubscription>;
  /** @deprecated use numberOfCurrentlyAcceptedForFirstInterviewJobApplications instead */
  numberOfAcceptedForFirstInterviewJobApplications: Scalars['Int']['output'];
  numberOfCurrentlyAcceptedForFirstInterviewJobApplications: Scalars['Int']['output'];
  numberOfHiredJobApplications: Scalars['Int']['output'];
  numberOfJobApplications: Scalars['Int']['output'];
  numberOfNewJobApplications: Scalars['Int']['output'];
  numberOfOngoingRecruitmentTasks: Scalars['Int']['output'];
  numberOfPositionsToFill: Scalars['Int']['output'];
  numberOfRecruitmentTaskRecommendations: Scalars['Int']['output'];
  perksDescription?: Maybe<Scalars['String']['output']>;
  /** When set to null, there's no priority */
  priorityIndicator?: Maybe<JobOfferPriorityIndicator>;
  profileDescription?: Maybe<Scalars['String']['output']>;
  /** For admin staffing only. Get the potential last recruitment task recommendation of this job offer for a given recruiter */
  recruiterLatestRecruitmentTaskRecommendation?: Maybe<RecruitmentTaskRecommendation>;
  /** For admin staffing only. Get the potential last recruitment task of this job offer for a given recruiter */
  recruiterRecruitmentTask?: Maybe<RecruitmentTask>;
  recruitmentAdvice?: Maybe<Scalars['String']['output']>;
  recruitmentProcess?: Maybe<Scalars['String']['output']>;
  remoteWorkPolicy?: Maybe<RemoteWorkPolicy>;
  replacedJobApplication?: Maybe<JobApplication>;
  skills: Array<Skill>;
  status: JobOfferStatus;
  technicalSkillsDescription?: Maybe<Scalars['String']['output']>;
  /** Defined when a job offer is terminated, it's a string for now */
  terminationReason?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  totalNumberOfAcceptedForFirstInterviewJobApplications: Scalars['Int']['output'];
  typicalProfileUrl?: Maybe<Scalars['String']['output']>;
  videoAskUrl?: Maybe<Scalars['String']['output']>;
  /** Defined when a hiring manager self booked a video brief meeting */
  videoBriefMeeting?: Maybe<VideoBriefMeeting>;
};

export type JobOfferRecruiterLatestRecruitmentTaskRecommendationArgs = {
  recruiterId: Scalars['String']['input'];
};

export type JobOfferRecruiterRecruitmentTaskArgs = {
  recruiterId: Scalars['String']['input'];
};

export type JobOfferLead = {
  __typename?: 'JobOfferLead';
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  jobBoard: Scalars['String']['output'];
  jobBoardUrl: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  publicatedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
};

export type JobOfferLeadOffsetBasedConnection = {
  __typename?: 'JobOfferLeadOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<JobOfferLead>;
  totalCount: Scalars['Int']['output'];
};

export type JobOfferMatchingCriterion = {
  __typename?: 'JobOfferMatchingCriterion';
  isMatching: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export enum JobOfferNotificationEvent {
  ApplicantHired = 'APPLICANT_HIRED',
  JobApplicationSubmitted = 'JOB_APPLICATION_SUBMITTED',
}

export type JobOfferNotificationSubscription = {
  __typename?: 'JobOfferNotificationSubscription';
  events: Array<JobOfferNotificationEvent>;
  id: Scalars['ID']['output'];
  subscriber: User;
};

export type JobOfferOffsetBasedConnection = {
  __typename?: 'JobOfferOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<JobOffer>;
  totalCount: Scalars['Int']['output'];
};

export enum JobOfferPriorityIndicator {
  NoOngoingJobApplication = 'NO_ONGOING_JOB_APPLICATION',
}

export type JobOfferStaffingCopyStrategySelectedRecruitersInput = {
  jobOfferId: Scalars['ID']['input'];
  jobOfferToCopyStaffingFromId: Scalars['ID']['input'];
};

export type JobOfferStaffingExploratoryStrategySelectedRecruitersInput = {
  jobOfferId: Scalars['ID']['input'];
};

export type JobOfferStaffingRecruiterReport = {
  __typename?: 'JobOfferStaffingRecruiterReport';
  error?: Maybe<Scalars['String']['output']>;
  recruiterEmail: Scalars['String']['output'];
  recruiterId: Scalars['String']['output'];
};

export enum JobOfferStaffingRecruiterTier {
  CopiedFromAnotherJobOffer = 'COPIED_FROM_ANOTHER_JOB_OFFER',
  JobCategoryBest = 'JOB_CATEGORY_BEST',
  JobCategoryExplorer = 'JOB_CATEGORY_EXPLORER',
  JobCategoryNewcomer = 'JOB_CATEGORY_NEWCOMER',
  RelevantProfileOwner = 'RELEVANT_PROFILE_OWNER',
}

export type JobOfferStaffingStandardStrategySelectedRecruitersInput = {
  jobOfferId: Scalars['ID']['input'];
};

export enum JobOfferStatus {
  CreationFormCompleted = 'CREATION_FORM_COMPLETED',
  Draft = 'DRAFT',
  HuntStarted = 'HUNT_STARTED',
  StaffingStarted = 'STAFFING_STARTED',
  VideoBriefPublished = 'VIDEO_BRIEF_PUBLISHED',
}

export type JobOffersFilters = {
  isTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  searchedText?: InputMaybe<Scalars['String']['input']>;
};

export enum LegalForm {
  IndividualEntrepreneur = 'INDIVIDUAL_ENTREPRENEUR',
}

export type LinkedInEducationInput = {
  degree?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  school: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type LinkedInExperienceInput = {
  companyName: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  jobTitle: Scalars['String']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type MarkInvitationAsOpenedInput = {
  /** The email of the invitee */
  email: Scalars['String']['input'];
  /** The id of the invitation */
  id: Scalars['ID']['input'];
};

export type MarkMessagesAsReadInput = {
  lastSeenMessageId: Scalars['String']['input'];
};

export type MarkProfileAsNotOpenToWorkInput = {
  profileId: Scalars['ID']['input'];
};

export type MarkProfileAsOpenToWorkInput = {
  profileId: Scalars['ID']['input'];
};

export type Message = {
  __typename?: 'Message';
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  files: Array<StoredFile>;
  id: Scalars['ID']['output'];
  jobApplicationStatus?: Maybe<JobApplicationStatus>;
  myReadStatus: MessageReadStatus;
};

export type MessageFileInput = {
  /** The unique file name as it is stored in the dsitributed storage */
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  originalFileName: Scalars['String']['input'];
};

export type MessageFileUploadUrlAndFileNameInput = {
  fileType: Scalars['String']['input'];
  originalFileName?: InputMaybe<Scalars['String']['input']>;
};

export type MessageOffsetBasedConnection = {
  __typename?: 'MessageOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<Message>;
  totalCount: Scalars['Int']['output'];
};

export enum MessageReadStatus {
  NotRead = 'NOT_READ',
  Read = 'READ',
}

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  body: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastEditor?: Maybe<User>;
  title: Scalars['String']['output'];
};

export enum MessageTemplateContext {
  HiringManagerAcceptsJobApplicationForFirstInterview = 'HIRING_MANAGER_ACCEPTS_JOB_APPLICATION_FOR_FIRST_INTERVIEW',
  NoContextProvided = 'NO_CONTEXT_PROVIDED',
  RecruiterSubmitsJobApplication = 'RECRUITER_SUBMITS_JOB_APPLICATION',
}

export type MessageTemplatesFilters = {
  context: MessageTemplateContext;
};

export type Mutation = {
  __typename?: 'Mutation';
  abandonRecruitmentTask: RecruitmentTask;
  acceptInvitation: User;
  acceptJobApplicationForFirstInterview: JobApplication;
  acceptRecruitmentTaskRecommendation: RecruitmentTaskRecommendation;
  addPotentialJobCategory: JobCategory;
  addPotentialNewJobCategory: JobCategory;
  addProfilePotentialSkill: Profile;
  addProfileToCVBank: Profile;
  agreeToTermsOfService: User;
  attributeCanonicalProfileToRecruiter: Scalars['Boolean']['output'];
  bookVideoBriefMeeting: VideoBriefMeeting;
  /** Tries to classify a job offer from title and text. It creates the job offer even if the classification does not succeed */
  classifyAndCreateJobOffer: ClassificationJob;
  completeJobOfferCreationForm: JobOffer;
  computeProfileJobApplicationRecommendations: ProfileJobApplicationRecommendationsComputation;
  computeProfileJobOfferMatchingAlgorithmAccuracy: ProfileJobOfferMatchingAlgorithm;
  computeProfileJobOfferPotentialMatches: Scalars['String']['output'];
  createCollectPaymentDetailsCheckoutSession: CheckoutSession;
  createEmployerMessageTemplate: MessageTemplate;
  createHireGaranteeCheckoutSession: CheckoutSession;
  createInvitation: Invitation;
  createInvoice: Invoice;
  createMyEmployer: User;
  createProfile: Profile;
  createSearchFirm: SearchFirm;
  createStripeCustomerCheckoutSession: StripeCustomerCheckoutSession;
  createStripeCustomerPortalSession: StripeCustomerPortalSession;
  createUser: User;
  createUserLead: UserLead;
  declareApplicantHiredByMyCompany: JobApplication;
  deleteCVBankAlert: CvBankAlert;
  deleteEmployerMessageTemplate: MessageTemplate;
  editProfilePersonalNote: Profile;
  /** Use expressInterestInProfiles for batched version of this mutation */
  expressInterestInProfile: JobOffer;
  expressInterestInProfiles: JobOffer;
  extractJobOfferSkills: JobOffer;
  extractJobOffersSkills: Scalars['Boolean']['output'];
  generateSearchFirmContract: Contract;
  markInvitationAsOpened: PublicInvitation;
  markMessagesAsRead: Message;
  markProfileAsNotOpenToWork: Profile;
  markProfileAsOpenToWork: Profile;
  openChatChannel: ChatChannel;
  postJobOfferNews: News;
  postMessage: Message;
  rateRecruitmentTask: RecruitmentTaskRating;
  rejectJobApplication: JobApplication;
  rejectRecruitmentTaskRecommendation: RecruitmentTaskRecommendation;
  removeProfileFromCVBank: Profile;
  reportJobApplicationAsRejected: JobApplication;
  requestFirstAppointment: Scalars['Boolean']['output'];
  requestRecruitmentProcessOutsourcingQuotation: Scalars['Boolean']['output'];
  sendEmailManually: Scalars['Boolean']['output'];
  sendOneToOneMessageToJobOfferRecruiters: Scalars['Boolean']['output'];
  setUpCVBankAlert: CvBankAlert;
  /** Staff a job offer with several recruiters. It returns the id of the recruiters that were already staffed on the given jobOffer */
  staffJobOffer: Array<JobOfferStaffingRecruiterReport>;
  /** Staff a recruiter on several job offers. It returns the title of the job offers that were already staffed for the given recruiter */
  staffRecruiter: Array<RecruiterStaffingJobOfferReport>;
  submitJobApplication: JobApplication;
  subscribeHiringManagerToJobOfferNotifications: JobOffer;
  takeRecruitmentTaskViaJobApplicationRecommendation: RecruitmentTask;
  terminateJobOffer: JobOffer;
  unsubscribeHiringManagerFromJobOfferNotifications: JobOffer;
  updateCVBankProfileDescription: Profile;
  /** @deprecated This mutation was renamed updateRecruiterCarbonCopyCouples */
  updateCarbonCopyCouples: Scalars['Boolean']['output'];
  updateEmployer: Employer;
  updateEmployerLogo: Employer;
  updateEmployerMessageTemplate: MessageTemplate;
  updateJobOffer: JobOffer;
  updateMyEmployerLogo: Employer;
  updateMyProfilePicture: User;
  updateProfile: Profile;
  updateProfileAcademicBackgrounds: Profile;
  updateProfileJobCategories: Profile;
  updateProfileSkills: Profile;
  updateRecruiterActivitySectorSpecializations: Scalars['Boolean']['output'];
  updateRecruiterCarbonCopyCouples: Scalars['Boolean']['output'];
  updateRecruiterDeprecatedJobCategoryAssociations: Scalars['Boolean']['output'];
  /**
   * For now, this mutation update the recruiter's deprecatedJobCategories.
   * @deprecated Use updateRecruiterDeprecatedJobCategoryAssociations instead. Update the website, wait a few days, then update this mutation to update the new jobCategories
   */
  updateRecruiterJobCategoryAssociations: Scalars['Boolean']['output'];
  /** For now, use this mutation to update the recruiter's new jobCategories. Delete this field as soon as the mutation updateRecruiterJobCategoryAssociations updates the new jobCategories */
  updateRecruiterNewJobCategoryAssociations: Scalars['Boolean']['output'];
  updateSearchFirm: SearchFirm;
  updateUser: User;
  upsertCanonicalProfile: Scalars['Boolean']['output'];
  upsertJobOfferLeads: Array<JobOfferLead>;
  viewCVBankProfile: ViewCvBankProfileResponse;
  visitLinkedInProfile: Scalars['Boolean']['output'];
};

export type MutationAbandonRecruitmentTaskArgs = {
  input: AbandonRecruitmentTaskInput;
};

export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput;
};

export type MutationAcceptJobApplicationForFirstInterviewArgs = {
  input: AcceptJobApplicationForFirstInterviewInput;
};

export type MutationAcceptRecruitmentTaskRecommendationArgs = {
  recommendationId: Scalars['String']['input'];
};

export type MutationAddPotentialJobCategoryArgs = {
  input: AddPotentialJobCategoryInput;
};

export type MutationAddPotentialNewJobCategoryArgs = {
  input: AddPotentialJobCategoryInput;
};

export type MutationAddProfilePotentialSkillArgs = {
  input: AddProfilePotentialSkillInput;
};

export type MutationAddProfileToCvBankArgs = {
  input: AddProfileToCvBankInput;
};

export type MutationAgreeToTermsOfServiceArgs = {
  input: AgreeToTermsOfServiceInput;
};

export type MutationAttributeCanonicalProfileToRecruiterArgs = {
  input: AttributeCanonicalProfileToRecruiterInput;
};

export type MutationBookVideoBriefMeetingArgs = {
  input: BookVideoBriefMeetingInput;
};

export type MutationClassifyAndCreateJobOfferArgs = {
  input: ClassifyAndCreateJobOfferInput;
};

export type MutationCompleteJobOfferCreationFormArgs = {
  input: CompleteJobOfferCreationFormInput;
};

export type MutationComputeProfileJobApplicationRecommendationsArgs = {
  input: ComputeProfileJobApplicationRecommendationsInput;
};

export type MutationComputeProfileJobOfferPotentialMatchesArgs = {
  input: ComputeProfileJobOfferPotentialMatchesInput;
};

export type MutationCreateEmployerMessageTemplateArgs = {
  input: CreateEmployerMessageTemplateInput;
};

export type MutationCreateHireGaranteeCheckoutSessionArgs = {
  input: CreateHireGaranteeCheckoutSessionInput;
};

export type MutationCreateInvitationArgs = {
  input: CreateInvitationInput;
};

export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};

export type MutationCreateMyEmployerArgs = {
  input: CreateMyEmployerInput;
};

export type MutationCreateProfileArgs = {
  input: CreateProfileInput;
};

export type MutationCreateSearchFirmArgs = {
  input: CreateSearchFirmInput;
};

export type MutationCreateStripeCustomerCheckoutSessionArgs = {
  input: CreateStripeCustomerCheckoutSessionInput;
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserLeadArgs = {
  input: CreateUserLeadInput;
};

export type MutationDeclareApplicantHiredByMyCompanyArgs = {
  input?: InputMaybe<DeclareApplicantHiredByMyCompanyInput>;
};

export type MutationDeleteCvBankAlertArgs = {
  input: DeleteCvBankAlertInput;
};

export type MutationDeleteEmployerMessageTemplateArgs = {
  input: DeleteEmployerMessageTemplateInput;
};

export type MutationEditProfilePersonalNoteArgs = {
  input: EditProfilePersonalNoteInput;
};

export type MutationExpressInterestInProfileArgs = {
  input: ExpressInterestInProfileInput;
};

export type MutationExpressInterestInProfilesArgs = {
  input: ExpressInterestInProfilesInput;
};

export type MutationExtractJobOfferSkillsArgs = {
  input: ExtractJobOfferSkillsInput;
};

export type MutationExtractJobOffersSkillsArgs = {
  filters?: InputMaybe<ExtractJobOffersSkillsFilters>;
};

export type MutationGenerateSearchFirmContractArgs = {
  input: GenerateSearchFirmContractInput;
};

export type MutationMarkInvitationAsOpenedArgs = {
  input: MarkInvitationAsOpenedInput;
};

export type MutationMarkMessagesAsReadArgs = {
  input: MarkMessagesAsReadInput;
};

export type MutationMarkProfileAsNotOpenToWorkArgs = {
  input: MarkProfileAsNotOpenToWorkInput;
};

export type MutationMarkProfileAsOpenToWorkArgs = {
  input: MarkProfileAsOpenToWorkInput;
};

export type MutationOpenChatChannelArgs = {
  input: OpenChatChannelInput;
};

export type MutationPostJobOfferNewsArgs = {
  input: PostJobOfferNewsInput;
};

export type MutationPostMessageArgs = {
  input: PostMessageInput;
};

export type MutationRateRecruitmentTaskArgs = {
  input: RateRecruitmentTaskInput;
};

export type MutationRejectJobApplicationArgs = {
  input: RejectJobApplicationInput;
};

export type MutationRejectRecruitmentTaskRecommendationArgs = {
  input: RejectRecruitementTaskRecommendationInput;
};

export type MutationRemoveProfileFromCvBankArgs = {
  input: RemoveProfileFromCvBankInput;
};

export type MutationReportJobApplicationAsRejectedArgs = {
  input: ReportJobApplicationAsRejectedInput;
};

export type MutationRequestFirstAppointmentArgs = {
  input: RequestFirstAppointmentInput;
};

export type MutationRequestRecruitmentProcessOutsourcingQuotationArgs = {
  input: RequestRecruitmentProcessOutsourcingQuotationInput;
};

export type MutationSendOneToOneMessageToJobOfferRecruitersArgs = {
  input: SendOneToOneMessageToJobOfferRecruitersInput;
};

export type MutationSetUpCvBankAlertArgs = {
  input: SetUpCvBankAlertInput;
};

export type MutationStaffJobOfferArgs = {
  input: StaffJobOfferInput;
};

export type MutationStaffRecruiterArgs = {
  input: StaffRecruiterInput;
};

export type MutationSubmitJobApplicationArgs = {
  input: SubmitJobApplicationInput;
};

export type MutationSubscribeHiringManagerToJobOfferNotificationsArgs = {
  input: SubscribeHiringManagerToJobOfferNotificationsInput;
};

export type MutationTakeRecruitmentTaskViaJobApplicationRecommendationArgs = {
  input: TakeRecruitmentTaskViaJobApplicationRecommendationInput;
};

export type MutationTerminateJobOfferArgs = {
  input: TerminateJobOfferInput;
};

export type MutationUnsubscribeHiringManagerFromJobOfferNotificationsArgs = {
  input: UnsubscribeHiringManagerFromJobOfferNotificationsInput;
};

export type MutationUpdateCvBankProfileDescriptionArgs = {
  input: UpdateCvBankProfileDescriptionInput;
};

export type MutationUpdateCarbonCopyCouplesArgs = {
  input: UpdateCarbonCopyCouplesInput;
};

export type MutationUpdateEmployerArgs = {
  input: UpdateEmployerInput;
};

export type MutationUpdateEmployerLogoArgs = {
  input: UpdateEmployerLogoInput;
};

export type MutationUpdateEmployerMessageTemplateArgs = {
  input: UpdateEmployerMessageTemplateInput;
};

export type MutationUpdateJobOfferArgs = {
  input: UpdateJobOfferInput;
};

export type MutationUpdateMyEmployerLogoArgs = {
  input: UpdateMyEmployerLogoInput;
};

export type MutationUpdateMyProfilePictureArgs = {
  input: UpdateMyProfilePictureInput;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateProfileAcademicBackgroundsArgs = {
  input: UpdateProfileAcademicBackgroundsInput;
};

export type MutationUpdateProfileJobCategoriesArgs = {
  input: UpdateProfileJobCategoriesInput;
};

export type MutationUpdateProfileSkillsArgs = {
  input: UpdateProfileSkillsInput;
};

export type MutationUpdateRecruiterActivitySectorSpecializationsArgs = {
  input: UpdateRecruiterActivitySectorSpecializationsInput;
};

export type MutationUpdateRecruiterCarbonCopyCouplesArgs = {
  input: UpdateCarbonCopyCouplesInput;
};

export type MutationUpdateRecruiterDeprecatedJobCategoryAssociationsArgs = {
  recruiterDeprecatedJobCategoryAssociationInputs: Array<RecruiterDeprecatedJobCategoryAssociationInput>;
};

export type MutationUpdateRecruiterJobCategoryAssociationsArgs = {
  recruiterJobCategoryAssociationInputs: Array<RecruiterJobCategoryAssociationInput>;
};

export type MutationUpdateRecruiterNewJobCategoryAssociationsArgs = {
  recruiterJobCategoryAssociationInputs: Array<RecruiterJobCategoryAssociationInput>;
};

export type MutationUpdateSearchFirmArgs = {
  input: UpdateSearchFirmInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpsertCanonicalProfileArgs = {
  input: UpsertCanonicalProfileInput;
};

export type MutationUpsertJobOfferLeadsArgs = {
  input: Array<UpsertJobOfferLeadsInput>;
};

export type MutationViewCvBankProfileArgs = {
  input: ViewCvBankProfileInput;
};

export type MutationVisitLinkedInProfileArgs = {
  input: VisitLinkedInProfileInput;
};

export type MyUser = User | UserLead;

export type NewJobCategoriesFilters = {
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<JobCategoryStatus>>;
};

export type News = {
  __typename?: 'News';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creator: User;
  id: Scalars['ID']['output'];
};

export type NewsOffsetBasedConnection = {
  __typename?: 'NewsOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<News>;
  totalCount: Scalars['Int']['output'];
};

export type OffsetBasedPaginationParams = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type OpenChatChannelInput = {
  interlocutorIds: Array<Scalars['String']['input']>;
};

export type PaginatedJobOfferSuggestedAnonymizedProfilesInput = {
  jobOfferId: Scalars['String']['input'];
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type PaginatedJobOffersInput = {
  filters?: InputMaybe<JobOffersFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type PaginatedRecruitersInput = {
  filters?: InputMaybe<RecruitersFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export enum PaymentServiceProviderName {
  Stripe = 'Stripe',
}

export type Permission = {
  __typename?: 'Permission';
  name: PermissionName;
};

export enum PermissionName {
  AddProfileToCvBank = 'ADD_PROFILE_TO_CV_BANK',
  CanUseMatching = 'CAN_USE_MATCHING',
  ReadHiringManagerPhoneNumber = 'READ_HIRING_MANAGER_PHONE_NUMBER',
}

export type PostJobOfferNewsInput = {
  body: Scalars['String']['input'];
  jobOfferId: Scalars['String']['input'];
};

export type PostMessageInput = {
  /** If <body> is not provided, then the <files> field is required or an error will be thrown */
  body?: InputMaybe<Scalars['String']['input']>;
  chatChannelId: Scalars['String']['input'];
  /** If <files> is not provided, then the <body> field is required or an error will be thrown */
  files?: InputMaybe<Array<MessageFileInput>>;
};

export type PriceInput = {
  amountInCents: Scalars['Int']['input'];
  currencyCode: CurrencyIso4217Code;
};

export type Profile = {
  __typename?: 'Profile';
  academicBackgrounds: Array<AcademicBackground>;
  approximateFirstJobStartDate?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<User>;
  currentEmployer?: Maybe<Employer>;
  curriculumVitaeUrl?: Maybe<Scalars['String']['output']>;
  cvBankDescription?: Maybe<CvBankProfileDescription>;
  cvBankDiscoverabilityPeriods: Array<CvBankProfileDiscoverabilityPeriod>;
  educations: Array<Education>;
  email?: Maybe<Scalars['String']['output']>;
  experiences: Array<Experience>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDiscoverableInCVBank: Scalars['Boolean']['output'];
  /** @deprecated For now, this field returns deprecatedJobCategories. Use newJobCategories field instead */
  jobCategories: Array<JobCategory>;
  lastJobApplicationRecommendationsComputation?: Maybe<ProfileJobApplicationRecommendationsComputation>;
  lastName: Scalars['String']['output'];
  linkedInProfileUrl?: Maybe<Scalars['String']['output']>;
  myNote?: Maybe<ProfileNote>;
  newJobCategories: Array<JobCategory>;
  openToWorkUntil?: Maybe<Scalars['DateTime']['output']>;
  origin: ProfileCreationOrigin;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  preferredRemoteWorkPolicy?: Maybe<RemoteWorkPolicy>;
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  salaryClaimAmount?: Maybe<Scalars['Int']['output']>;
  salaryClaimCurrencyCode?: Maybe<CurrencyIso4217Code>;
  skills: Array<Skill>;
  updatedAt: Scalars['DateTime']['output'];
  urbanArea?: Maybe<UrbanArea>;
  validSkills: Array<Skill>;
};

export type ProfileAcademicBackground = {
  __typename?: 'ProfileAcademicBackground';
  academicBackground: AcademicBackground;
  id: Scalars['ID']['output'];
  profile: Profile;
};

export type ProfileCvBankAddibility = {
  __typename?: 'ProfileCVBankAddibility';
  canBeAdded: Scalars['Boolean']['output'];
  reason?: Maybe<ProfileCvBankNonAddibilityReason>;
};

export enum ProfileCvBankNonAddibilityReason {
  AlreadyIn = 'ALREADY_IN',
  NotEnoughInformation = 'NOT_ENOUGH_INFORMATION',
  OwnershipAlreadyTaken = 'OWNERSHIP_ALREADY_TAKEN',
}

export type ProfileCvBankViewCountViewerPairsInput = {
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
  profileId: Scalars['String']['input'];
};

export enum ProfileCreationOrigin {
  CopiedFromCanonicalProfile = 'COPIED_FROM_CANONICAL_PROFILE',
  LinkedinWebExtension = 'LINKEDIN_WEB_EXTENSION',
  Website = 'WEBSITE',
}

export type ProfileEducationInput = {
  degree?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * The ID associated to the school.
   *     Eg: For this URL "https://www.linkedin.com/company/2481168/", LinkedIn will redirect us to "https://www.linkedin.com/company/netatmo/"
   *     what we want to store is "2481168" (it's the simplest data that can be fetched on the front-end to identify a company)
   *
   */
  linkedInSchoolId?: InputMaybe<Scalars['String']['input']>;
  school: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProfileExperienceInput = {
  companyName: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  jobTitle: Scalars['String']['input'];
  /**
   * The ID associated to the company.
   *     Eg: For this URL "https://www.linkedin.com/company/2481168/", LinkedIn will redirect us to "https://www.linkedin.com/company/netatmo/"
   *     what we want to store is "2481168" (it's the simplest data that can be fetched on the front-end to identify a company)
   *
   */
  linkedInCompanyId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProfileIdentificationDataInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  linkedInUrl?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileJobApplicationRecommendationsComputation = {
  __typename?: 'ProfileJobApplicationRecommendationsComputation';
  id: Scalars['ID']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: ProfileJobApplicationRecommendationsComputationStatus;
};

export enum ProfileJobApplicationRecommendationsComputationStatus {
  Failed = 'failed',
  Ongoing = 'ongoing',
  Successful = 'successful',
}

export type ProfileJobOfferMatchingAlgorithm = {
  __typename?: 'ProfileJobOfferMatchingAlgorithm';
  createdAt: Scalars['DateTime']['output'];
  datasetSize?: Maybe<Scalars['Int']['output']>;
  falseNegatives?: Maybe<Array<Scalars['String']['output']>>;
  falsePositives?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
};

export type ProfileJobOfferPotentialMatch = {
  __typename?: 'ProfileJobOfferPotentialMatch';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isScoreAccurate?: Maybe<Scalars['Boolean']['output']>;
  jobOffer: JobOffer;
  profile: Profile;
  score: Scalars['Int']['output'];
  shouldMatch?: Maybe<Scalars['Boolean']['output']>;
};

export type ProfileNote = {
  __typename?: 'ProfileNote';
  body: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ProfileOffsetBasedConnection = {
  __typename?: 'ProfileOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<Profile>;
  totalCount: Scalars['Int']['output'];
};

export type ProfilePictureUploadUrlAndFileNameInput = {
  fileType: Scalars['String']['input'];
};

export type ProfileProfilePictureUploadUrlAndFileNameInput = {
  fileType: Scalars['String']['input'];
};

export type PublicInvitation = {
  __typename?: 'PublicInvitation';
  businessType: BusinessType;
  id: Scalars['ID']['output'];
  organizationName: Scalars['String']['output'];
  status: InvitationStatus;
};

export type PublicInvitationInput = {
  /** The email of the invitee */
  email: Scalars['String']['input'];
  /** The id of the invitation */
  id: Scalars['ID']['input'];
};

export type Query = {
  __typename?: 'Query';
  academicBackgrounds: Array<AcademicBackground>;
  activitySectors: Array<ActivitySector>;
  anonymizedProfile: AnonymizedProfile;
  chatChannel: ChatChannel;
  checkIfSearchFirmNameIsAvailable: Scalars['Boolean']['output'];
  companyLogoUploadUrlAndFileName: Awss3UploadUrlAndKey;
  countries: Array<Country>;
  coworkers: Array<User>;
  curriculumVitaeAWSS3UploadUrlAndKey: Awss3UploadUrlAndKey;
  cvBankProfiles: CvBankProfileOffsetBasedConnection;
  /** @deprecated Use jobCategories instead */
  deprecatedJobCategories: Array<JobCategory>;
  employerJobOfferLead: JobOfferLead;
  /** @deprecated Prefer the employerPaginatedJobOffers for performance */
  employerJobOffers: Array<JobOffer>;
  employerPaginatedJobOfferLeads: JobOfferLeadOffsetBasedConnection;
  employerPaginatedJobOffers: JobOfferOffsetBasedConnection;
  employers: Array<Employer>;
  extensionConfig: ExtensionConfig;
  hasAlreadyAppliedToJobOffer: Scalars['Boolean']['output'];
  /** Get all invitations of the current user's organization */
  invitations: Array<Invitation>;
  jobApplication: JobApplication;
  jobApplicationRecommendation: JobApplicationRecommendation;
  jobCategories: Array<JobCategory>;
  jobOffer: JobOffer;
  jobOfferApplications: Array<JobApplication>;
  jobOfferNewsFeed: NewsOffsetBasedConnection;
  jobOfferRecruitmentTaskRecommendations: Array<RecruitmentTaskRecommendation>;
  jobOfferRecruitmentTasks: Array<RecruitmentTask>;
  /** Get a list of explorer recruiters that have had only a few interactions with Muzzo so far */
  jobOfferStaffingCopyStrategySelectedRecruiters: Array<User>;
  /** Get a list of explorer recruiters that have had only a few interactions with Muzzo so far */
  jobOfferStaffingExploratoryStrategySelectedRecruiters: Array<User>;
  /** Get a list of recruiters from all tiers for this job offer */
  jobOfferStaffingStandardStrategySelectedRecruiters: Array<User>;
  /** @deprecated use profile.lastJobApplicationRecommendationsComputation instead */
  lastProfileJobApplicationRecommendationsComputation?: Maybe<ProfileJobApplicationRecommendationsComputation>;
  liveness: Scalars['String']['output'];
  /** @deprecated This query will be deleted after the onboarding EPIC: use myUser query instead */
  me?: Maybe<User>;
  messageFileUploadUrlAndFileName: Awss3UploadUrlAndKey;
  messages: MessageOffsetBasedConnection;
  myCVBankAlerts: Array<CvBankAlert>;
  myChatChannels: ChatChannelOffsetBasedConnection;
  myEmployerMembers: Array<User>;
  myEmployerMessageTemplates: Array<MessageTemplate>;
  myLastUsedEmployerMessageTemplate?: Maybe<MessageTemplate>;
  myUser?: Maybe<MyUser>;
  /** @deprecated Use jobCategories instead */
  newJobCategories: Array<JobCategory>;
  paginatedAnonymizedProfiles: AnonymizedProfileOffsetBasedConnection;
  paginatedJobOfferSuggestedAnonymizedProfiles: AnonymizedProfileOffsetBasedConnection;
  paginatedJobOffers: JobOfferOffsetBasedConnection;
  paginatedRecruiterProfiles: ProfileOffsetBasedConnection;
  paginatedRecruiters: RecruiterOffsetBasedConnection;
  profile: Profile;
  /** @deprecated Use Profile.academicBackgrounds instead */
  profileAcademicBackgrounds: Array<ProfileAcademicBackground>;
  profileCVBankAddibility: ProfileCvBankAddibility;
  profileCVBankViewCountViewerPairs: CvBankViewCountViewerPairOffsetBasedConnection;
  profileCareerDataFromLinkedIn: CareerData;
  profileJobApplicationRecommendationRanking: Array<JobApplicationRecommendation>;
  profileJobApplicationRecommendationsComputation: ProfileJobApplicationRecommendationsComputation;
  profileJobOfferPotentialMatches: Array<ProfileJobOfferPotentialMatch>;
  profilePictureUploadUrlAndFileName: Awss3UploadUrlAndKey;
  profileProfilePictureUploadUrlAndFileName: Awss3UploadUrlAndKey;
  /** Get a public invitation for account creation purpose. */
  publicInvitation: PublicInvitation;
  recruiter: User;
  recruiterJobApplications: JobApplicationOffsetBasedConnection;
  /** @deprecated Use "paginatedRecruiterProfiles" instead */
  recruiterProfiles: Array<Profile>;
  recruiterRecruitmentTaskByJobOfferId: RecruitmentTask;
  recruiterRecruitmentTaskRecommendations: Array<RecruitmentTaskRecommendation>;
  recruiterRecruitmentTaskRecommendationsCount: Scalars['Int']['output'];
  recruiterRecruitmentTasks: RecruitmentTaskOffsetBasedConnection;
  recruitersStaffedOnJobOffer: Array<User>;
  recruitmentTask: RecruitmentTask;
  recruitmentTaskRecommendation: RecruitmentTaskRecommendation;
  recruitmentTasksOfRecruiterForMyEmployer: Array<RecruitmentTask>;
  schools: Array<School>;
  skills: Array<Skill>;
  supportChatUserIdentifier: Scalars['String']['output'];
  termsOfService?: Maybe<TermsOfService>;
  unreadMessagesCount: Scalars['Int']['output'];
  urbanAreas: Array<UrbanArea>;
  validSkills: Array<Skill>;
  videoBriefAwsS3UploadUrlAndKey: Awss3UploadUrlAndKey;
};

export type QueryAnonymizedProfileArgs = {
  input: GetProfileInput;
};

export type QueryChatChannelArgs = {
  id: Scalars['String']['input'];
};

export type QueryCheckIfSearchFirmNameIsAvailableArgs = {
  input: CheckIfSearchFirmNameIsAvailableInput;
};

export type QueryCompanyLogoUploadUrlAndFileNameArgs = {
  input: CompanyLogoUploadUrlAndFileNameInput;
};

export type QueryCurriculumVitaeAwss3UploadUrlAndKeyArgs = {
  input: CurriculumVitaeAwss3UploadUrlAndKeyInput;
};

export type QueryCvBankProfilesArgs = {
  filters?: InputMaybe<CvBankProfilesFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryDeprecatedJobCategoriesArgs = {
  filters?: InputMaybe<DeprecatedJobCategoriesFilters>;
};

export type QueryEmployerJobOfferLeadArgs = {
  jobOfferLeadId: Scalars['String']['input'];
};

export type QueryEmployerPaginatedJobOfferLeadsArgs = {
  filters?: InputMaybe<EmployerJobOfferLeadsFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryEmployerPaginatedJobOffersArgs = {
  filters?: InputMaybe<EmployerJobOffersFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryEmployersArgs = {
  input: GetEmployersInput;
};

export type QueryHasAlreadyAppliedToJobOfferArgs = {
  input: HasAlreadyAppliedToJobOfferInput;
};

export type QueryInvitationsArgs = {
  filters?: InputMaybe<InvitationsFilters>;
};

export type QueryJobApplicationArgs = {
  id: Scalars['String']['input'];
};

export type QueryJobApplicationRecommendationArgs = {
  input: GetJobApplicationRecommendationInput;
};

export type QueryJobCategoriesArgs = {
  filters?: InputMaybe<JobCategoriesFilters>;
};

export type QueryJobOfferArgs = {
  id: Scalars['String']['input'];
};

export type QueryJobOfferApplicationsArgs = {
  filters?: InputMaybe<JobApplicationsFilters>;
  jobOfferId: Scalars['String']['input'];
};

export type QueryJobOfferNewsFeedArgs = {
  input: GetJobOfferNewsFeedInput;
};

export type QueryJobOfferRecruitmentTaskRecommendationsArgs = {
  filters: RecruitmentTaskRecommendationsFilters;
  jobOfferId: Scalars['String']['input'];
};

export type QueryJobOfferRecruitmentTasksArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type QueryJobOfferStaffingCopyStrategySelectedRecruitersArgs = {
  input: JobOfferStaffingCopyStrategySelectedRecruitersInput;
};

export type QueryJobOfferStaffingExploratoryStrategySelectedRecruitersArgs = {
  input: JobOfferStaffingExploratoryStrategySelectedRecruitersInput;
};

export type QueryJobOfferStaffingStandardStrategySelectedRecruitersArgs = {
  input: JobOfferStaffingStandardStrategySelectedRecruitersInput;
};

export type QueryLastProfileJobApplicationRecommendationsComputationArgs = {
  input: GetLastProfileJobApplicationRecommendationsComputationInput;
};

export type QueryMessageFileUploadUrlAndFileNameArgs = {
  input: MessageFileUploadUrlAndFileNameInput;
};

export type QueryMessagesArgs = {
  input: GetMessagesInput;
};

export type QueryMyChatChannelsArgs = {
  input: GetMyChatChannelsInput;
};

export type QueryMyEmployerMessageTemplatesArgs = {
  filters?: InputMaybe<MessageTemplatesFilters>;
};

export type QueryMyLastUsedEmployerMessageTemplateArgs = {
  filters: MessageTemplatesFilters;
};

export type QueryNewJobCategoriesArgs = {
  filters?: InputMaybe<NewJobCategoriesFilters>;
};

export type QueryPaginatedAnonymizedProfilesArgs = {
  filters?: InputMaybe<AnonymizedProfilesFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryPaginatedJobOfferSuggestedAnonymizedProfilesArgs = {
  input: PaginatedJobOfferSuggestedAnonymizedProfilesInput;
};

export type QueryPaginatedJobOffersArgs = {
  input: PaginatedJobOffersInput;
};

export type QueryPaginatedRecruiterProfilesArgs = {
  filters?: InputMaybe<RecruiterProfilesFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
  sort?: InputMaybe<RecruiterProfilesSort>;
};

export type QueryPaginatedRecruitersArgs = {
  input: PaginatedRecruitersInput;
};

export type QueryProfileArgs = {
  input: GetProfileInput;
};

export type QueryProfileAcademicBackgroundsArgs = {
  input: GetProfileAcademicBackgroundsInput;
};

export type QueryProfileCvBankAddibilityArgs = {
  profileId: Scalars['String']['input'];
};

export type QueryProfileCvBankViewCountViewerPairsArgs = {
  input: ProfileCvBankViewCountViewerPairsInput;
};

export type QueryProfileCareerDataFromLinkedInArgs = {
  input: GetCareerDataFromLinkedInInput;
};

export type QueryProfileJobApplicationRecommendationRankingArgs = {
  input: GetProfileJobApplicationRecommendationRankingInput;
};

export type QueryProfileJobApplicationRecommendationsComputationArgs = {
  input: GetProfileJobApplicationRecommendationsComputationInput;
};

export type QueryProfileJobOfferPotentialMatchesArgs = {
  input: GetProfileJobOfferPotentialMatchesInput;
};

export type QueryProfilePictureUploadUrlAndFileNameArgs = {
  input: ProfilePictureUploadUrlAndFileNameInput;
};

export type QueryProfileProfilePictureUploadUrlAndFileNameArgs = {
  input: ProfileProfilePictureUploadUrlAndFileNameInput;
};

export type QueryPublicInvitationArgs = {
  input: PublicInvitationInput;
};

export type QueryRecruiterArgs = {
  id: Scalars['String']['input'];
};

export type QueryRecruiterJobApplicationsArgs = {
  filters?: InputMaybe<JobApplicationsFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryRecruiterProfilesArgs = {
  filters?: InputMaybe<RecruiterProfilesFilters>;
};

export type QueryRecruiterRecruitmentTaskByJobOfferIdArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type QueryRecruiterRecruitmentTaskRecommendationsArgs = {
  filters: RecruitmentTaskRecommendationsFilters;
};

export type QueryRecruiterRecruitmentTaskRecommendationsCountArgs = {
  filters: RecruitmentTaskRecommendationsFilters;
};

export type QueryRecruiterRecruitmentTasksArgs = {
  filters?: InputMaybe<RecruiterRecruitmentTasksFilters>;
  offsetBasedPaginationParams: OffsetBasedPaginationParams;
};

export type QueryRecruitersStaffedOnJobOfferArgs = {
  input: RecruitersStaffedOnJobOfferInput;
};

export type QueryRecruitmentTaskArgs = {
  id: Scalars['String']['input'];
};

export type QueryRecruitmentTaskRecommendationArgs = {
  id: Scalars['String']['input'];
};

export type QueryRecruitmentTasksOfRecruiterForMyEmployerArgs = {
  input: RecruitmentTasksOfRecruiterForMyEmployerInput;
};

export type QuerySchoolsArgs = {
  input: GetSchoolsInput;
};

export type QuerySkillsArgs = {
  input: GetSkillsInput;
};

export type QueryTermsOfServiceArgs = {
  input: TermsOfServiceInput;
};

export type QueryValidSkillsArgs = {
  input: GetValidSkillsInput;
};

export type QueryVideoBriefAwsS3UploadUrlAndKeyArgs = {
  input: VideoBriefAwss3UploadUrlAndKeyInput;
};

export type RateRecruitmentTaskInput = {
  ratingValue: Scalars['Float']['input'];
  recruitmentTaskId: Scalars['String']['input'];
};

export type RecruiterActivityMetrics = {
  __typename?: 'RecruiterActivityMetrics';
  jobApplicationMetrics: RecruiterJobApplicationMetrics;
};

export type RecruiterActivityMetricsJobApplicationMetricsArgs = {
  input: RecruiterJobApplicationMetricsInput;
};

export type RecruiterDeprecatedJobCategoryAssociationInput = {
  deprecatedJobCategoryId: Scalars['String']['input'];
};

export type RecruiterJobApplicationMetrics = {
  __typename?: 'RecruiterJobApplicationMetrics';
  hireConversionRateInBasisPoints: Scalars['Int']['output'];
  interviewConversionRateInBasisPoints: Scalars['Int']['output'];
  numberOfJobApplications: Scalars['Int']['output'];
};

export type RecruiterJobApplicationMetricsInput = {
  /** The date of the observation. Helpful for instance to differ the studied period from the last job application. If not provided, it will be set to today */
  observeAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Metrics will be computed out of job applications submitted after this date. If not provided, the date will be set to 30 days ago */
  submittedAfter?: InputMaybe<Scalars['DateTime']['input']>;
  /** Metrics will be computed out of job applications submitted before this date. If not provided, the date will be set to today */
  submittedBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RecruiterJobCategoryAssociationInput = {
  /** For now, jobCategoryId input can correspond to either a deprecatedJobCategoryId or a jobCategoryId. */
  jobCategoryId: Scalars['String']['input'];
};

export type RecruiterOffsetBasedConnection = {
  __typename?: 'RecruiterOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<User>;
  totalCount: Scalars['Int']['output'];
};

export type RecruiterProfilesFilters = {
  andFilterSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  isDiscoverableInCVBank?: InputMaybe<Scalars['Boolean']['input']>;
  isOpenToWork?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryId. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  /** For now, use this newJobCategoryIds input to filter by newJobCategories. Delete this field as soon as the input jobCategoryIds filters by new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  orFilterSkillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchedText?: InputMaybe<Scalars['String']['input']>;
  urbanAreaId?: InputMaybe<Scalars['String']['input']>;
};

export type RecruiterProfilesSort = {
  /** This was originally added to see that sortBy createdAt is the default, and make this behaviour explicit on the API */
  sortBy?: InputMaybe<Scalars['String']['input']>;
};

export type RecruiterRecruitmentTasksFilters = {
  isTerminated?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, this jobCategoryId input should correspond to a deprecatedJobCategoryId. */
  jobCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** For now, use this newJobCategoryId to filter by new jobCategoryId. Delete this field as soon as the input jobCategoryId corresponds to a new jobCategoryId */
  newJobCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** Provide the priority indicators you want to filter on. When nothing is passed, nothing will be filtered out */
  priorityIndicators?: InputMaybe<Array<JobOfferPriorityIndicator>>;
  recruitmentTaskStatus?: InputMaybe<RecruitmentTaskStatus>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type RecruiterStaffingJobOfferReport = {
  __typename?: 'RecruiterStaffingJobOfferReport';
  error?: Maybe<Scalars['String']['output']>;
  jobOfferId: Scalars['String']['output'];
  jobOfferTitle: Scalars['String']['output'];
};

export type RecruitersFilters = {
  searchedText?: InputMaybe<Scalars['String']['input']>;
};

export type RecruitersStaffedOnJobOfferInput = {
  jobOfferId: Scalars['String']['input'];
};

export type RecruitmentTask = {
  __typename?: 'RecruitmentTask';
  id: Scalars['ID']['output'];
  jobApplications: Array<JobApplication>;
  jobOffer: JobOffer;
  rating?: Maybe<RecruitmentTaskRating>;
  recruiter: User;
  recruiterFlatFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruiterSuccessFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  status: RecruitmentTaskStatus;
};

export type RecruitmentTaskOffsetBasedConnection = {
  __typename?: 'RecruitmentTaskOffsetBasedConnection';
  hasNextPage: Scalars['Boolean']['output'];
  nodes: Array<RecruitmentTask>;
  totalCount: Scalars['Int']['output'];
};

export type RecruitmentTaskRating = {
  __typename?: 'RecruitmentTaskRating';
  id: Scalars['ID']['output'];
  value: Scalars['Float']['output'];
};

export type RecruitmentTaskRecommendation = {
  __typename?: 'RecruitmentTaskRecommendation';
  createdAt: Scalars['DateTime']['output'];
  expirationDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  recruiter: User;
  recruiterFlatFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  recruiterSuccessFeeInBasisPoints?: Maybe<Scalars['Float']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status: RecruitmentTaskRecommendationStatus;
};

export enum RecruitmentTaskRecommendationStatus {
  Accepted = 'accepted',
  AcceptedViaJobApplicationRecommendation = 'accepted_via_job_application_recommendation',
  Expired = 'expired',
  Pending = 'pending',
  Rejected = 'rejected',
  WithdrawnByCustomerSuccessManager = 'withdrawn_by_customer_success_manager',
}

export type RecruitmentTaskRecommendationsFilters = {
  status?: InputMaybe<RecruitmentTaskRecommendationStatus>;
};

export enum RecruitmentTaskStatus {
  AbandonedByRecruiter = 'ABANDONED_BY_RECRUITER',
  EndedByInternalStaff = 'ENDED_BY_INTERNAL_STAFF',
  Ongoing = 'ONGOING',
}

export type RecruitmentTasksOfRecruiterForMyEmployerInput = {
  recruiterId: Scalars['String']['input'];
};

export type RejectJobApplicationInput = {
  jobApplicationId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export type RejectRecruitementTaskRecommendationInput = {
  reason: Scalars['String']['input'];
  recommendationId: Scalars['String']['input'];
};

export enum RemoteWorkPolicy {
  AuthorizedFullRemote = 'AUTHORIZED_FULL_REMOTE',
  FullOnsite = 'FULL_ONSITE',
  MandatoryFullRemote = 'MANDATORY_FULL_REMOTE',
  PartialRemote = 'PARTIAL_REMOTE',
}

export type RemoveProfileFromCvBankInput = {
  profileId: Scalars['String']['input'];
};

export type ReportJobApplicationAsRejectedInput = {
  jobApplicationId: Scalars['String']['input'];
};

export type RequestFirstAppointmentInput = {
  email: Scalars['String']['input'];
};

export type RequestRecruitmentProcessOutsourcingQuotationInput = {
  maximumDailyFeeInCents: Scalars['Int']['input'];
  minimumNumberOfOfficeDaysPerWeek: Scalars['Int']['input'];
  otherDetails?: InputMaybe<Scalars['String']['input']>;
  recruiterSpecializationJobCategoryIds: Array<Scalars['String']['input']>;
  startDate: Scalars['DateTime']['input'];
  taskDescription: Scalars['String']['input'];
  taskDurationInMonths: Scalars['Int']['input'];
};

export type Role = {
  __typename?: 'Role';
  name: RoleName;
};

export enum RoleName {
  CvBankSupplier = 'CV_BANK_SUPPLIER',
  ProfileMatchingUser = 'PROFILE_MATCHING_USER',
  SuperRecruiter = 'SUPER_RECRUITER',
}

export type School = {
  __typename?: 'School';
  id: Scalars['ID']['output'];
  linkedInSchoolId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type SearchFirm = {
  __typename?: 'SearchFirm';
  address?: Maybe<Address>;
  bankDetails?: Maybe<BankDetails>;
  /** A list of contracts associated to the search firm. */
  contracts?: Maybe<Array<Contract>>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  legalForm?: Maybe<LegalForm>;
  name: Scalars['String']['output'];
  numberOfRecruiters?: Maybe<Scalars['Int']['output']>;
  referenceClients: Array<Employer>;
  siret?: Maybe<Scalars['String']['output']>;
  subscriptions: Array<Subscription>;
};

export type SearchFirmLegalRepresentativeContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type SendOneToOneMessageToJobOfferRecruitersInput = {
  body: Scalars['String']['input'];
  jobOfferId: Scalars['String']['input'];
};

export type SetUpCvBankAlertInput = {
  searchCriteria: CvBankProfilesFilters;
  searchUrl: Scalars['String']['input'];
};

export type Skill = {
  __typename?: 'Skill';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  status: SkillStatus;
};

export enum SkillStatus {
  Invalid = 'INVALID',
  Pending = 'PENDING',
  Valid = 'VALID',
}

export type SkillsWithQueryModeInput = {
  ids: Array<Scalars['ID']['input']>;
  mode?: InputMaybe<EntitiesQueryMode>;
};

export type StaffJobOfferInput = {
  jobOfferId: Scalars['ID']['input'];
  recruiterIds: Array<Scalars['String']['input']>;
};

export type StaffRecruiterInput = {
  jobOffersIds: Array<Scalars['ID']['input']>;
  recruiterId: Scalars['String']['input'];
};

export type StoredFile = {
  __typename?: 'StoredFile';
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  originalFileName?: Maybe<Scalars['String']['output']>;
};

export type StripeCustomerCheckoutSession = {
  __typename?: 'StripeCustomerCheckoutSession';
  url: Scalars['String']['output'];
};

export type StripeCustomerPortalSession = {
  __typename?: 'StripeCustomerPortalSession';
  url: Scalars['String']['output'];
};

export type SubmitJobApplicationInput = {
  jobOfferId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['ID']['input'];
};

export type SubscribeHiringManagerToJobOfferNotificationsInput = {
  events: Array<JobOfferNotificationEvent>;
  hiringManagerId: Scalars['ID']['input'];
  jobOfferId: Scalars['ID']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['String']['output'];
  paymentServiceProviderName: PaymentServiceProviderName;
  status: SubscriptionStatus;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Incomplete = 'INCOMPLETE',
  IncompleteExpired = 'INCOMPLETE_EXPIRED',
  PastDue = 'PAST_DUE',
  Paused = 'PAUSED',
  Trialing = 'TRIALING',
  Unpaid = 'UNPAID',
}

export type SuccessFee = {
  __typename?: 'SuccessFee';
  basisPoints: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
};

export type TakeRecruitmentTaskViaJobApplicationRecommendationInput = {
  jobApplicationRecommendationId: Scalars['String']['input'];
};

export type TerminateJobOfferInput = {
  jobOfferId: Scalars['String']['input'];
  messageForRecruiters?: InputMaybe<Scalars['String']['input']>;
  terminationReason: Scalars['String']['input'];
};

export type TermsOfService = {
  __typename?: 'TermsOfService';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  version: Scalars['Float']['output'];
};

export type TermsOfServiceInput = {
  version?: InputMaybe<Scalars['Float']['input']>;
};

export type UnsubscribeHiringManagerFromJobOfferNotificationsInput = {
  events: Array<JobOfferNotificationEvent>;
  hiringManagerId: Scalars['ID']['input'];
  jobOfferId: Scalars['ID']['input'];
};

export type UpdateCvBankProfileDescriptionInput = {
  descriptionText?: InputMaybe<Scalars['String']['input']>;
  profileId: Scalars['String']['input'];
};

export type UpdateCarbonCopyCouplesInput = {
  carbonCopyUserIds: Array<Scalars['String']['input']>;
  topic: CarbonCopyTopic;
};

export type UpdateEmployerInput = {
  address?: InputMaybe<AddressInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['ID']['input']>;
  nonPoachingAgreementsDescription?: InputMaybe<Scalars['String']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployerLogoInput = {
  employerId: Scalars['String']['input'];
  logoFileName: Scalars['String']['input'];
};

export type UpdateEmployerMessageTemplateInput = {
  body: Scalars['String']['input'];
  messageTemplateId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
};

export type UpdateJobOfferInput = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  careerOutlook?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  currencyCode?: InputMaybe<CurrencyIso4217Code>;
  deprecatedJobCategoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  hasHealthInsurancePlan?: InputMaybe<Scalars['Boolean']['input']>;
  hasIncentiveBonus?: InputMaybe<Scalars['Boolean']['input']>;
  hasLuncheonVouchers?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  isTeleworkingAuthorized?: InputMaybe<Scalars['Boolean']['input']>;
  /** For now, this jobCategoryId input should correspond to a deprecatedJobCategoryId. */
  jobCategoryId?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  mandatoryProfileAttributes?: InputMaybe<Scalars['String']['input']>;
  maxNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  maximumRemuneration?: InputMaybe<Scalars['Int']['input']>;
  maximumVariableRemuneration?: InputMaybe<Scalars['Int']['input']>;
  minNumberOfYearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
  minimumRemuneration?: InputMaybe<Scalars['Int']['input']>;
  motivationalMessageToRecruiters?: InputMaybe<Scalars['String']['input']>;
  /** Fow now, use this field to update the jobOffer new jobCategoryId. Delete this field as soon as the field jobCategoryId corresponds to the new jobCategory */
  newJobCategoryId?: InputMaybe<Scalars['ID']['input']>;
  numberOfPositionsToFill?: InputMaybe<Scalars['Int']['input']>;
  perksDescription?: InputMaybe<Scalars['String']['input']>;
  profileDescription?: InputMaybe<Scalars['String']['input']>;
  recruitmentProcess?: InputMaybe<Scalars['String']['input']>;
  remoteWorkPolicy?: InputMaybe<RemoteWorkPolicy>;
  successFeeBasisPoints?: InputMaybe<Scalars['Int']['input']>;
  technicalSkillsDescription?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  typicalProfileUrl?: InputMaybe<Scalars['String']['input']>;
  videoBriefAwsS3Key?: InputMaybe<Scalars['String']['input']>;
  videoBriefFilename?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMyEmployerLogoInput = {
  logoFileName: Scalars['String']['input'];
};

export type UpdateMyProfilePictureInput = {
  profilePictureFileName: Scalars['String']['input'];
};

export type UpdateProfileAcademicBackgroundsInput = {
  academicBackgroundIds: Array<Scalars['String']['input']>;
  profileId: Scalars['String']['input'];
};

export type UpdateProfileInput = {
  academicBackgroundIds?: InputMaybe<Array<Scalars['String']['input']>>;
  approximateFirstJobStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeAwsS3Key?: InputMaybe<Scalars['String']['input']>;
  curriculumVitaeFilename?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryIds. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInProfileUrl?: InputMaybe<Scalars['String']['input']>;
  /** For now, use this newJobCategoryIds input to update the profile's new jobCategories. Delete this field as soon as the input jobCategoryIds updates the new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  potentialSkillNames?: InputMaybe<Array<Scalars['String']['input']>>;
  preferredRemoteWorkPolicy?: InputMaybe<Scalars['String']['input']>;
  profilePictureFilename?: InputMaybe<Scalars['String']['input']>;
  salaryClaimAmount?: InputMaybe<Scalars['Int']['input']>;
  salaryClaimCurrencyCode?: InputMaybe<Scalars['String']['input']>;
  skillIds?: InputMaybe<Array<Scalars['String']['input']>>;
  urbanAreaId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProfileJobCategoriesInput = {
  /** For now, this jobCategoryIds input should correspond to a list of deprecatedJobCategoryIds. */
  jobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** For now, use this newJobCategoryIds input to update the profile's new jobCategories. Delete this field as soon as the input jobCategoryIds updates the new jobCategories. */
  newJobCategoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  profileId: Scalars['String']['input'];
};

export type UpdateProfileSkillsInput = {
  profileId: Scalars['String']['input'];
  skillIds: Array<Scalars['String']['input']>;
};

export type UpdateRecruiterActivitySectorSpecializationsInput = {
  activitySectorIds: Array<Scalars['String']['input']>;
};

export type UpdateSearchFirmInput = {
  address?: InputMaybe<AddressInput>;
  bankDetails?: InputMaybe<BankDetailsInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfRecruiters?: InputMaybe<Scalars['Int']['input']>;
  siret?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  isInterestedInRecruitmentProcessOutsourcing?: InputMaybe<
    Scalars['Boolean']['input']
  >;
  lastName?: InputMaybe<Scalars['String']['input']>;
  liAt?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertCanonicalProfileInput = {
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  lastEnrichmentAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInVanityName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profilePictureFilename?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertJobOfferLeadsInput = {
  description: Scalars['String']['input'];
  domainName: Scalars['String']['input'];
  jobBoard: Scalars['String']['input'];
  jobBoardUrl: Scalars['String']['input'];
  location: Scalars['String']['input'];
  publicatedAt: Scalars['DateTime']['input'];
  title: Scalars['String']['input'];
};

export type UrbanArea = {
  __typename?: 'UrbanArea';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UrbanAreaWithRadiusInput = {
  id: Scalars['ID']['input'];
  /** The radius of the searched zone around the urban area expressed in meters. */
  radius?: InputMaybe<Scalars['Float']['input']>;
};

export type User = {
  __typename?: 'User';
  activityMetric: UserActivityMetric;
  carbonCopyCouplesCreatedByCurrentUser: Array<CarbonCopyCouple>;
  createdAt: Scalars['DateTime']['output'];
  cvBankCreditCount: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  employer?: Maybe<Employer>;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAuthorizedToUseMarketplace: Scalars['Boolean']['output'];
  isInterestedInRecruitmentProcessOutsourcing?: Maybe<
    Scalars['Boolean']['output']
  >;
  /** For admin staffing only. Get the potential last recruitment task recommendation of this recruiter for a given job offer */
  jobOfferLatestRecruitmentTaskRecommendation?: Maybe<RecruitmentTaskRecommendation>;
  /** For admin staffing only. Get the potential last recruitment task of this recruiter for a given job offer */
  jobOfferRecruitmentTask?: Maybe<RecruitmentTask>;
  jobOfferStaffingRecruiterTier?: Maybe<JobOfferStaffingRecruiterTier>;
  lastName: Scalars['String']['output'];
  mainBusinessType: BusinessType;
  myOneToOneChatChannelId?: Maybe<Scalars['String']['output']>;
  permissions: Array<Permission>;
  phoneNumber: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  recruiterActivitySectors: Array<ActivitySector>;
  recruiterDeprecatedJobCategories?: Maybe<Array<JobCategory>>;
  /**
   * For now, this field returns deprecatedJobCategories.
   * @deprecated Use recruiterDeprecatedJobCategories instead. Update the website, wait a few days, then update this field to return the new jobCategories
   */
  recruiterJobCategories?: Maybe<Array<JobCategory>>;
  /** For now, use this field to get the new jobCategories. Delete this field as soon as the field recruiterJobCategories returns the new jobCategories */
  recruiterNewJobCategories?: Maybe<Array<JobCategory>>;
  roles: Array<Role>;
  searchFirm?: Maybe<SearchFirm>;
  /** For admin staffing only. Get the potential end of partnership date for this recruiter */
  softEndOfPartnershipDate?: Maybe<Scalars['DateTime']['output']>;
  /** For admin staffing only. Get the potential end of partnership reason for this recruiter */
  softEndOfPartnershipReason?: Maybe<Scalars['String']['output']>;
  /** The last terms of services version the user agreed to. If null, the user never agreed to any terms of services. */
  termsOfServiceVersion?: Maybe<Scalars['Float']['output']>;
};

export type UserJobOfferLatestRecruitmentTaskRecommendationArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type UserJobOfferRecruitmentTaskArgs = {
  jobOfferId: Scalars['String']['input'];
};

export type UserActivityMetric = {
  __typename?: 'UserActivityMetric';
  /** The response rate of the user as basis points. For example, 50 means 0.5% */
  jobApplicationsResponseRate?: Maybe<Scalars['Int']['output']>;
  /** The average response time of the user in hours */
  jobApplicationsResponseTime?: Maybe<Scalars['Int']['output']>;
  /** The recruiter activity metrics. Only available for recruiters */
  recruiterActivityMetrics?: Maybe<RecruiterActivityMetrics>;
};

export type UserLead = {
  __typename?: 'UserLead';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  mainBusinessType: BusinessType;
};

export type VideoBriefAwss3UploadUrlAndKeyInput = {
  fileType: Scalars['String']['input'];
  jobOfferId: Scalars['String']['input'];
};

export type VideoBriefMeeting = {
  __typename?: 'VideoBriefMeeting';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jobOffer: JobOffer;
  jobOfferId: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ViewCvBankProfileInput = {
  profileId: Scalars['String']['input'];
};

export type ViewCvBankProfileResponse = {
  __typename?: 'ViewCVBankProfileResponse';
  profile: Profile;
  remainingCredits: Scalars['Int']['output'];
};

export type VisitLinkedInProfileInput = {
  currentEmployerName?: InputMaybe<Scalars['String']['input']>;
  educations?: InputMaybe<Array<ProfileEducationInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  experiences?: InputMaybe<Array<ProfileExperienceInput>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  linkedInProfilePictureUrl?: InputMaybe<Scalars['String']['input']>;
  linkedInVanityName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AcademicBackgroundFragment = {
  __typename?: 'AcademicBackground';
  id: string;
  name: string;
};

export type JobOfferPageAcademicBackgroundFragment = {
  __typename?: 'AcademicBackground';
  id: string;
  name: string;
};

export type AcademicBackgroundsQueryVariables = Exact<{ [key: string]: never }>;

export type AcademicBackgroundsQuery = {
  __typename?: 'Query';
  academicBackgrounds: Array<
    { __typename?: 'AcademicBackground' } & AcademicBackgroundFragment
  >;
};

export type OptionActivitySectorFragment = {
  __typename?: 'ActivitySector';
  id: string;
  name: string;
};

export type OptionActivitySectorsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OptionActivitySectorsQuery = {
  __typename?: 'Query';
  activitySectors: Array<
    { __typename?: 'ActivitySector' } & OptionActivitySectorFragment
  >;
};

export type UpdateCarbonCopyCouplesMutationVariables = Exact<{
  input: UpdateCarbonCopyCouplesInput;
}>;

export type UpdateCarbonCopyCouplesMutation = {
  __typename?: 'Mutation';
  updateCarbonCopyCouples: boolean;
};

export type InternalManagerWithMyOneToOneChatChannelIdOnlyFragment = {
  __typename?: 'InternalManager';
  myOneToOneChatChannelId?: string | null;
};

export type UserWithMyOneToOneChatChannelIdOnlyFragment = {
  __typename?: 'User';
  myOneToOneChatChannelId?: string | null;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: 'Query';
  countries: Array<{ __typename?: 'Country'; id: string; name: string }>;
};

export type EmployerJobOfferEmployerFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
  description?: string | null;
  logoUrl?: string | null;
  nonPoachingAgreementsDescription?: string | null;
};

export type RecruiterJobOfferEmployerFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
  description?: string | null;
  logoUrl?: string | null;
  nonPoachingAgreementsDescription?: string | null;
};

export type SearchFirmReferenceClientFragment = {
  __typename?: 'Employer';
  id: string;
  name: string;
  logoUrl?: string | null;
};

export type AcceptJobApplicationReturnPayloadFragment = {
  __typename?: 'JobApplication';
  id: string;
  statuses?: Array<
    { __typename?: 'JobApplicationStatus' } & JobApplicationStatusFragment
  > | null;
};

export type ChatRecruitmentTaskJobApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  statuses?: Array<
    { __typename?: 'JobApplicationStatus' } & JobApplicationStatusFragment
  > | null;
  profile: {
    __typename?: 'Profile';
    creator?: {
      __typename?: 'User';
      id: string;
      phoneNumber: string;
      email: string;
      firstName: string;
      lastName: string;
    } | null;
  } & BaseJobApplicationProfileFragment;
  recruitmentTask: {
    __typename?: 'RecruitmentTask';
  } & JobApplicationRecruitmentTaskFragment;
  jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
};

export type EmployerJobOfferApplicationFragment = {
  __typename?: 'JobApplication';
  id: string;
  recruitmentTask: {
    __typename?: 'RecruitmentTask';
  } & JobApplicationRecruitmentTaskFragment;
  jobOffer: { __typename?: 'JobOffer' } & JobApplicationJobOfferFragment;
  profile: { __typename?: 'Profile' } & EmployerJobApplicationProfileFragment;
  statuses?: Array<
    { __typename?: 'JobApplicationStatus' } & JobApplicationStatusFragment
  > | null;
};

export type JobApplicationWithStatusesFragment = {
  __typename?: 'JobApplication';
  id: string;
  statuses?: Array<
    { __typename?: 'JobApplicationStatus' } & JobApplicationStatusFragment
  > | null;
};

export type RejectJobApplicationReturnPayloadFragment = {
  __typename?: 'JobApplication';
} & JobApplicationWithStatusesFragment;

export type ReportJobApplicationAsRejectedReturnPayloadFragment = {
  __typename?: 'JobApplication';
} & JobApplicationWithStatusesFragment;

export type SubmitJobApplicationReturnPayloadFragment = {
  __typename?: 'JobApplication';
  id: string;
  profile: {
    __typename?: 'Profile';
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type AcceptJobApplicationForFirstInterviewMutationVariables = Exact<{
  input: AcceptJobApplicationForFirstInterviewInput;
}>;

export type AcceptJobApplicationForFirstInterviewMutation = {
  __typename?: 'Mutation';
  acceptJobApplicationForFirstInterview: {
    __typename?: 'JobApplication';
  } & AcceptJobApplicationReturnPayloadFragment;
};

export type RejectJobApplicationMutationVariables = Exact<{
  input: RejectJobApplicationInput;
}>;

export type RejectJobApplicationMutation = {
  __typename?: 'Mutation';
  rejectJobApplication: {
    __typename?: 'JobApplication';
  } & RejectJobApplicationReturnPayloadFragment;
};

export type ReportJobApplicationAsRejectedMutationVariables = Exact<{
  input: ReportJobApplicationAsRejectedInput;
}>;

export type ReportJobApplicationAsRejectedMutation = {
  __typename?: 'Mutation';
  reportJobApplicationAsRejected: {
    __typename?: 'JobApplication';
  } & ReportJobApplicationAsRejectedReturnPayloadFragment;
};

export type SubmitJobApplicationMutationVariables = Exact<{
  input: SubmitJobApplicationInput;
}>;

export type SubmitJobApplicationMutation = {
  __typename?: 'Mutation';
  submitJobApplication: {
    __typename?: 'JobApplication';
  } & SubmitJobApplicationReturnPayloadFragment;
};

export type JobApplicationStatusFragment = {
  __typename?: 'JobApplicationStatus';
  id: string;
  createdAt: string;
  name: JobApplicationStatusName;
  message?:
    | ({ __typename?: 'Message' } & JobApplicationStatusMessageFragment)
    | null;
};

export type JobApplicationStatusMessageFragment = {
  __typename?: 'Message';
  id: string;
  body?: string | null;
  createdAt: string;
  creator: {
    __typename?: 'User';
    id: string;
    firstName: string;
    lastName: string;
  };
};

export type EmployerJobOfferJobCategoryFragment = {
  __typename?: 'JobCategory';
  id: string;
  name: string;
};

export type EmployerDashboardJobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  createdAt: string;
  numberOfOngoingRecruitmentTasks: number;
  numberOfNewJobApplications: number;
  isTerminated: boolean;
  creator?: { __typename?: 'User'; id: string } | null;
};

export type EmployerJobOfferFragment = {
  __typename?: 'JobOffer';
  employer?:
    | ({ __typename?: 'Employer' } & EmployerJobOfferEmployerFragment)
    | null;
  jobCategory?:
    | ({ __typename?: 'JobCategory' } & EmployerJobOfferJobCategoryFragment)
    | null;
} & JobOfferPageJobOfferFragment;

export type JobApplicationJobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  title: string;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
};

export type JobOfferPageJobOfferFragment = {
  __typename?: 'JobOffer';
  id: string;
  briefingVideoUrl?: string | null;
  videoAskUrl?: string | null;
  title: string;
  minimumRemuneration?: number | null;
  maximumRemuneration?: number | null;
  maximumVariableRemuneration?: number | null;
  currencyCode?: CurrencyIso4217Code | null;
  contractType?: string | null;
  description: string;
  profileDescription?: string | null;
  location?: string | null;
  numberOfPositionsToFill: number;
  recruitmentAdvice?: string | null;
  recruitmentProcess?: string | null;
  minNumberOfYearsOfExperience?: number | null;
  maxNumberOfYearsOfExperience?: number | null;
  careerOutlook?: string | null;
  typicalProfileUrl?: string | null;
  hasIncentiveBonus?: boolean | null;
  hasLuncheonVouchers?: boolean | null;
  hasHealthInsurancePlan?: boolean | null;
  perksDescription?: string | null;
  mandatoryProfileAttributes?: string | null;
  technicalSkillsDescription?: string | null;
  isTerminated: boolean;
  remoteWorkPolicy?: RemoteWorkPolicy | null;
  creator?:
    | ({ __typename?: 'User' } & JobOfferPageJobOfferCreatorFragment)
    | null;
  academicBackgrounds?: Array<
    {
      __typename?: 'AcademicBackground';
    } & JobOfferPageAcademicBackgroundFragment
  > | null;
  latestSuccessFee?: {
    __typename?: 'SuccessFee';
    id: string;
    basisPoints: number;
  } | null;
  internalManager?:
    | ({
        __typename?: 'InternalManager';
      } & JobOfferPageJobOfferInternalManagerFragment)
    | null;
};

export type RecruiterJobOfferFragment = {
  __typename?: 'JobOffer';
  employer?:
    | ({ __typename?: 'Employer' } & RecruiterJobOfferEmployerFragment)
    | null;
} & JobOfferPageJobOfferFragment;

export type TerminatedJobOfferReturnPayloadFragment = {
  __typename?: 'JobOffer';
  id: string;
  isTerminated: boolean;
};

export type TerminateJobOfferMutationVariables = Exact<{
  input: TerminateJobOfferInput;
}>;

export type TerminateJobOfferMutation = {
  __typename?: 'Mutation';
  terminateJobOffer: {
    __typename?: 'JobOffer';
  } & TerminatedJobOfferReturnPayloadFragment;
};

export type EmployerJobOfferQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type EmployerJobOfferQuery = {
  __typename?: 'Query';
  jobOffer: { __typename?: 'JobOffer' } & EmployerJobOfferFragment;
};

export type JobOfferQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type JobOfferQuery = {
  __typename?: 'Query';
  jobOffer: { __typename?: 'JobOffer' } & EmployerDashboardJobOfferFragment;
};

export type JobOfferApplicationsQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
  jobApplicationsFilters?: InputMaybe<JobApplicationsFilters>;
}>;

export type JobOfferApplicationsQuery = {
  __typename?: 'Query';
  jobOfferApplications: Array<
    { __typename?: 'JobApplication' } & EmployerJobOfferApplicationFragment
  >;
};

export type RecruiterJobOfferQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RecruiterJobOfferQuery = {
  __typename?: 'Query';
  jobOffer: { __typename?: 'JobOffer' } & RecruiterJobOfferFragment;
};

export type BaseJobApplicationProfileFragment = {
  __typename?: 'Profile';
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  phoneNumber?: string | null;
  linkedInProfileUrl?: string | null;
  curriculumVitaeUrl?: string | null;
};

export type CreateProfilePreviewFragment = {
  __typename?: 'Profile';
  id: string;
  firstName: string;
  lastName: string;
};

export type EmployerJobApplicationProfileFragment = {
  __typename?: 'Profile';
  creator?:
    | ({ __typename?: 'User' } & EmployerJobApplicationProfileCreatorFragment)
    | null;
} & BaseJobApplicationProfileFragment;

export type CreateProfileMutationVariables = Exact<{
  input: CreateProfileInput;
}>;

export type CreateProfileMutation = {
  __typename?: 'Mutation';
  createProfile: { __typename?: 'Profile' } & CreateProfilePreviewFragment;
};

export type EmployersQueryVariables = Exact<{
  input: GetEmployersInput;
}>;

export type EmployersQuery = {
  __typename?: 'Query';
  employers: Array<{ __typename?: 'Employer'; id: string; name: string }>;
};

export type LastProfileJobApplicationRecommendationsComputationDetailedFragment =
  {
    __typename?: 'ProfileJobApplicationRecommendationsComputation';
    id: string;
    startedAt: string;
    status: ProfileJobApplicationRecommendationsComputationStatus;
  };

export type ProfileJobApplicationRecommendationRankingDetailsFragment = {
  __typename?: 'JobApplicationRecommendation';
  id: string;
  createdAt: string;
  score?: number | null;
  recruiterSuccessFeeInBasisPoints?: number | null;
  recruiterFlatFeeInBasisPoints?: number | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    currencyCode?: CurrencyIso4217Code | null;
    title: string;
    minimumRemuneration?: number | null;
    maximumRemuneration?: number | null;
    maximumVariableRemuneration?: number | null;
    location?: string | null;
    minNumberOfYearsOfExperience?: number | null;
    maxNumberOfYearsOfExperience?: number | null;
    remoteWorkPolicy?: RemoteWorkPolicy | null;
    latestSuccessFee?: {
      __typename?: 'SuccessFee';
      basisPoints: number;
    } | null;
    employer?: { __typename?: 'Employer'; name: string } | null;
  };
  recruitmentTask?: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
  } | null;
  jobOfferMatchingCriteria?: Array<{
    __typename?: 'JobOfferMatchingCriterion';
    name: string;
    isMatching: boolean;
  }> | null;
};

export type ComputeProfileJobApplicationRecommendationsMutationVariables =
  Exact<{
    input: ComputeProfileJobApplicationRecommendationsInput;
  }>;

export type ComputeProfileJobApplicationRecommendationsMutation = {
  __typename?: 'Mutation';
  computeProfileJobApplicationRecommendations: {
    __typename?: 'ProfileJobApplicationRecommendationsComputation';
    id: string;
  };
};

export type UpdateProfileAcademicBackgroundsMutationVariables = Exact<{
  input: UpdateProfileAcademicBackgroundsInput;
}>;

export type UpdateProfileAcademicBackgroundsMutation = {
  __typename?: 'Mutation';
  updateProfileAcademicBackgrounds: {
    __typename?: 'Profile';
    id: string;
    academicBackgrounds: Array<
      { __typename?: 'AcademicBackground' } & AcademicBackgroundFragment
    >;
  };
};

export type UpdateProfileSkillsMutationVariables = Exact<{
  input: UpdateProfileSkillsInput;
}>;

export type UpdateProfileSkillsMutation = {
  __typename?: 'Mutation';
  updateProfileSkills: {
    __typename?: 'Profile';
    id: string;
    skills: Array<{ __typename?: 'Skill' } & SkillFragment>;
  };
};

export type LastProfileJobApplicationRecommendationsComputationQueryVariables =
  Exact<{
    input: GetLastProfileJobApplicationRecommendationsComputationInput;
  }>;

export type LastProfileJobApplicationRecommendationsComputationQuery = {
  __typename?: 'Query';
  lastProfileJobApplicationRecommendationsComputation?:
    | ({
        __typename?: 'ProfileJobApplicationRecommendationsComputation';
      } & LastProfileJobApplicationRecommendationsComputationDetailedFragment)
    | null;
};

export type ProfileJobApplicationRecommendationRankingQueryVariables = Exact<{
  input: GetProfileJobApplicationRecommendationRankingInput;
}>;

export type ProfileJobApplicationRecommendationRankingQuery = {
  __typename?: 'Query';
  profileJobApplicationRecommendationRanking: Array<
    {
      __typename?: 'JobApplicationRecommendation';
    } & ProfileJobApplicationRecommendationRankingDetailsFragment
  >;
};

export type ProfileJobApplicationRecommendationsComputationQueryVariables =
  Exact<{
    input: GetProfileJobApplicationRecommendationsComputationInput;
  }>;

export type ProfileJobApplicationRecommendationsComputationQuery = {
  __typename?: 'Query';
  profileJobApplicationRecommendationsComputation: {
    __typename?: 'ProfileJobApplicationRecommendationsComputation';
    id: string;
    status: ProfileJobApplicationRecommendationsComputationStatus;
  };
};

export type ProfileJobOfferPotentialMatchesDetailsFragment = {
  __typename?: 'ProfileJobOfferPotentialMatch';
  comment?: string | null;
  id: string;
  isScoreAccurate?: boolean | null;
  score: number;
  shouldMatch?: boolean | null;
  jobOffer: {
    __typename?: 'JobOffer';
    id: string;
    title: string;
    employer?: { __typename?: 'Employer'; name: string } | null;
  };
  profile: { __typename?: 'Profile'; id: string; email?: string | null };
};

export type ComputeProfileJobOfferPotentialMatchesMutationVariables = Exact<{
  input: ComputeProfileJobOfferPotentialMatchesInput;
}>;

export type ComputeProfileJobOfferPotentialMatchesMutation = {
  __typename?: 'Mutation';
  computeProfileJobOfferPotentialMatches: string;
};

export type AdminPanelProfileQueryVariables = Exact<{
  input: GetProfileInput;
}>;

export type AdminPanelProfileQuery = {
  __typename?: 'Query';
  profile: {
    __typename?: 'Profile';
    creator?: {
      __typename?: 'User';
      id: string;
      email: string;
      firstName: string;
      lastName: string;
    } | null;
  };
};

export type ProfileJobOfferPotentialMatchesQueryVariables = Exact<{
  input: GetProfileJobOfferPotentialMatchesInput;
}>;

export type ProfileJobOfferPotentialMatchesQuery = {
  __typename?: 'Query';
  profileJobOfferPotentialMatches: Array<
    {
      __typename?: 'ProfileJobOfferPotentialMatch';
    } & ProfileJobOfferPotentialMatchesDetailsFragment
  >;
};

export type EditProfilePersonalNotePreviewFragment = {
  __typename?: 'Profile';
  id: string;
  myNote?: ({ __typename?: 'ProfileNote' } & ProfileNoteFragment) | null;
};

export type ProfileNoteFragment = {
  __typename?: 'ProfileNote';
  id: string;
  createdAt: string;
  updatedAt: string;
  body: string;
};

export type EditProfilePersonalNoteMutationVariables = Exact<{
  input: EditProfilePersonalNoteInput;
}>;

export type EditProfilePersonalNoteMutation = {
  __typename?: 'Mutation';
  editProfilePersonalNote: {
    __typename?: 'Profile';
  } & EditProfilePersonalNotePreviewFragment;
};

export type ChatRecruitmentTaskWithApplicationsFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  jobOffer: { __typename?: 'JobOffer'; id: string; title: string };
  jobApplications: Array<
    {
      __typename?: 'JobApplication';
    } & ChatRecruitmentTaskJobApplicationFragment
  >;
};

export type EmployerRecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  recruiter: { __typename?: 'User' } & EmployerRecruitmentTaskRecruiterFragment;
};

export type JobApplicationRecruitmentTaskFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  recruiter: { __typename?: 'User'; id: string };
};

export type JobOfferRecruitmentTaskPreviewFragment = {
  __typename?: 'RecruitmentTask';
  id: string;
  status: RecruitmentTaskStatus;
  recruiter: { __typename?: 'User'; id: string };
};

export type AbandonRecruitmentTaskMutationVariables = Exact<{
  input: AbandonRecruitmentTaskInput;
}>;

export type AbandonRecruitmentTaskMutation = {
  __typename?: 'Mutation';
  abandonRecruitmentTask: {
    __typename?: 'RecruitmentTask';
    id: string;
    status: RecruitmentTaskStatus;
  };
};

export type JobOfferRecruitmentTaskPreviewsQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type JobOfferRecruitmentTaskPreviewsQuery = {
  __typename?: 'Query';
  jobOfferRecruitmentTasks: Array<
    { __typename?: 'RecruitmentTask' } & JobOfferRecruitmentTaskPreviewFragment
  >;
};

export type JobOfferRecruitmentTasksQueryVariables = Exact<{
  jobOfferId: Scalars['String']['input'];
}>;

export type JobOfferRecruitmentTasksQuery = {
  __typename?: 'Query';
  jobOfferRecruitmentTasks: Array<
    { __typename?: 'RecruitmentTask' } & EmployerRecruitmentTaskFragment
  >;
};

export type RecruitmentTasksOfRecruiterForMyEmployerQueryVariables = Exact<{
  input: RecruitmentTasksOfRecruiterForMyEmployerInput;
}>;

export type RecruitmentTasksOfRecruiterForMyEmployerQuery = {
  __typename?: 'Query';
  recruitmentTasksOfRecruiterForMyEmployer: Array<
    {
      __typename?: 'RecruitmentTask';
    } & ChatRecruitmentTaskWithApplicationsFragment
  >;
};

export type AcceptRecruitmentTaskRecommendationMutationVariables = Exact<{
  recommendationId: Scalars['String']['input'];
}>;

export type AcceptRecruitmentTaskRecommendationMutation = {
  __typename?: 'Mutation';
  acceptRecruitmentTaskRecommendation: {
    __typename?: 'RecruitmentTaskRecommendation';
    id: string;
    status: RecruitmentTaskRecommendationStatus;
  };
};

export type CreateSearchFirmReturnPayloadFragment = {
  __typename?: 'SearchFirm';
  id: string;
  name: string;
  numberOfRecruiters?: number | null;
  legalForm?: LegalForm | null;
};

export type EmployerRecruitmentTaskRecruiterSearchFirmFragment = {
  __typename?: 'SearchFirm';
  id: string;
  name: string;
};

export type RecruiterSeenByEmployerSearchFirmFragment = {
  __typename?: 'SearchFirm';
  id: string;
  name: string;
  description?: string | null;
  referenceClients: Array<
    { __typename?: 'Employer' } & SearchFirmReferenceClientFragment
  >;
};

export type RecruiterSettingsSearchFirmFragment = {
  __typename?: 'SearchFirm';
  id: string;
  name: string;
  description?: string | null;
  siret?: string | null;
  address?: {
    __typename?: 'Address';
    id: string;
    streetAddress: string;
    locality: string;
    postalCode: string;
    country?: { __typename?: 'Country'; id: string; name: string } | null;
  } | null;
  bankDetails?: {
    __typename?: 'BankDetails';
    id: string;
    iban?: string | null;
  } | null;
};

export type CreateSearchFirmMutationVariables = Exact<{
  input: CreateSearchFirmInput;
}>;

export type CreateSearchFirmMutation = {
  __typename?: 'Mutation';
  createSearchFirm: {
    __typename?: 'SearchFirm';
  } & CreateSearchFirmReturnPayloadFragment;
};

export type GenerateSearchFirmContractMutationVariables = Exact<{
  input: GenerateSearchFirmContractInput;
}>;

export type GenerateSearchFirmContractMutation = {
  __typename?: 'Mutation';
  generateSearchFirmContract: { __typename?: 'Contract'; id: string };
};

export type UpdateSearchFirmMutationVariables = Exact<{
  input: UpdateSearchFirmInput;
}>;

export type UpdateSearchFirmMutation = {
  __typename?: 'Mutation';
  updateSearchFirm: {
    __typename?: 'SearchFirm';
  } & RecruiterSettingsSearchFirmFragment;
};

export type CheckIfSearchFirmNameIsAvailableQueryVariables = Exact<{
  input: CheckIfSearchFirmNameIsAvailableInput;
}>;

export type CheckIfSearchFirmNameIsAvailableQuery = {
  __typename?: 'Query';
  checkIfSearchFirmNameIsAvailable: boolean;
};

export type SkillFragment = {
  __typename?: 'Skill';
  id: string;
  name: string;
  status: SkillStatus;
};

export type SkillWithoutStatusFragment = {
  __typename?: 'Skill';
  id: string;
  name: string;
};

export type SkillsQueryVariables = Exact<{
  input: GetSkillsInput;
}>;

export type SkillsQuery = {
  __typename?: 'Query';
  skills: Array<{ __typename?: 'Skill' } & SkillFragment>;
};

export type ValidSkillsQueryVariables = Exact<{
  input: GetValidSkillsInput;
}>;

export type ValidSkillsQuery = {
  __typename?: 'Query';
  validSkills: Array<{ __typename?: 'Skill' } & SkillWithoutStatusFragment>;
};

export type GetSupportChatUserIdentifierQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSupportChatUserIdentifierQuery = {
  __typename?: 'Query';
  supportChatUserIdentifier: string;
};

export type UrbanAreasQueryVariables = Exact<{ [key: string]: never }>;

export type UrbanAreasQuery = {
  __typename?: 'Query';
  urbanAreas: Array<{ __typename?: 'UrbanArea'; id: string; name: string }>;
};

export type BaseUserFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

export type EmployerJobApplicationProfileCreatorFragment = {
  __typename?: 'User';
  myOneToOneChatChannelId?: string | null;
  searchFirm?: {
    __typename?: 'SearchFirm';
    id: string;
    name: string;
    description?: string | null;
  } | null;
} & BaseUserFragment;

export type EmployerRecruitmentTaskRecruiterFragment = {
  __typename?: 'User';
  myOneToOneChatChannelId?: string | null;
  searchFirm?:
    | ({
        __typename?: 'SearchFirm';
      } & EmployerRecruitmentTaskRecruiterSearchFirmFragment)
    | null;
} & BaseUserFragment;

export type JobOfferPageJobOfferCreatorFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  profilePictureUrl?: string | null;
  employer?: { __typename?: 'Employer'; id: string; name: string } | null;
  activityMetric: {
    __typename?: 'UserActivityMetric';
    jobApplicationsResponseTime?: number | null;
    jobApplicationsResponseRate?: number | null;
  };
} & UserWithMyOneToOneChatChannelIdOnlyFragment;

export type JobOfferPageJobOfferInternalManagerFragment = {
  __typename?: 'InternalManager';
  id: string;
  firstName: string;
  lastName: string;
  profilePictureUrl?: string | null;
} & InternalManagerWithMyOneToOneChatChannelIdOnlyFragment;

export type RecruiterSeenByEmployerFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  createdAt: string;
  searchFirm?:
    | ({
        __typename?: 'SearchFirm';
      } & RecruiterSeenByEmployerSearchFirmFragment)
    | null;
};

export type RecruiterSettingsCoworkerFragment = {
  __typename?: 'User';
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  carbonCopyCouplesCreatedByCurrentUser: Array<{
    __typename?: 'CarbonCopyCouple';
    id: string;
    topic: string;
  }>;
};

export type UpdateUserReturnPayloadFragment = {
  __typename?: 'User';
  id: string;
  phoneNumber: string;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: { __typename?: 'User' } & UpdateUserReturnPayloadFragment;
};

export type RecruiterOnboardingUserQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecruiterOnboardingUserQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  } | null;
};

export type RecruiterSeenByEmployerQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;

export type RecruiterSeenByEmployerQuery = {
  __typename?: 'Query';
  recruiter: { __typename?: 'User' } & RecruiterSeenByEmployerFragment;
};

export type RecruiterSettingsCoworkersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type RecruiterSettingsCoworkersQuery = {
  __typename?: 'Query';
  coworkers: Array<{ __typename?: 'User' } & RecruiterSettingsCoworkerFragment>;
};

export type UpdateRecruiterActivitySectorSpecializationsMutationVariables =
  Exact<{
    input: UpdateRecruiterActivitySectorSpecializationsInput;
  }>;

export type UpdateRecruiterActivitySectorSpecializationsMutation = {
  __typename?: 'Mutation';
  updateRecruiterActivitySectorSpecializations: boolean;
};

export type CreateUserLeadMutationVariables = Exact<{
  input: CreateUserLeadInput;
}>;

export type CreateUserLeadMutation = {
  __typename?: 'Mutation';
  createUserLead: { __typename?: 'UserLead' } & CreateUserLead_UserLeadFragment;
};

export type CreateUserLead_UserLeadFragment = {
  __typename: 'UserLead';
  id: string;
  email: string;
  mainBusinessType: BusinessType;
};

export const AcademicBackgroundFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AcademicBackgroundFragment, unknown>;
export const OptionActivitySectorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionActivitySector' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivitySector' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OptionActivitySectorFragment, unknown>;
export const JobApplicationStatusMessageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationStatusMessageFragment, unknown>;
export const JobApplicationStatusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationStatusFragment, unknown>;
export const AcceptJobApplicationReturnPayloadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AcceptJobApplicationReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptJobApplicationReturnPayloadFragment,
  unknown
>;
export const JobApplicationRecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationRecruitmentTaskFragment, unknown>;
export const JobApplicationJobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationJobOfferFragment, unknown>;
export const BaseJobApplicationProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseJobApplicationProfileFragment, unknown>;
export const BaseUserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BaseUserFragment, unknown>;
export const EmployerJobApplicationProfileCreatorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobApplicationProfileCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobApplicationProfileCreatorFragment,
  unknown
>;
export const EmployerJobApplicationProfileFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobApplicationProfileCreator',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobApplicationProfileCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobApplicationProfileFragment, unknown>;
export const EmployerJobOfferApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruitmentTask',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationJobOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobApplicationProfile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobApplicationProfileCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobApplicationProfileCreator',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobOfferApplicationFragment, unknown>;
export const JobApplicationWithStatusesFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobApplicationWithStatusesFragment, unknown>;
export const RejectJobApplicationReturnPayloadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RejectJobApplicationReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectJobApplicationReturnPayloadFragment,
  unknown
>;
export const ReportJobApplicationAsRejectedReturnPayloadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ReportJobApplicationAsRejectedReturnPayload',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportJobApplicationAsRejectedReturnPayloadFragment,
  unknown
>;
export const SubmitJobApplicationReturnPayloadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubmitJobApplicationReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitJobApplicationReturnPayloadFragment,
  unknown
>;
export const EmployerDashboardJobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerDashboardJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNewJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerDashboardJobOfferFragment, unknown>;
export const UserWithMyOneToOneChatChannelIdOnlyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithMyOneToOneChatChannelIdOnly' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UserWithMyOneToOneChatChannelIdOnlyFragment,
  unknown
>;
export const JobOfferPageJobOfferCreatorFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UserWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithMyOneToOneChatChannelIdOnly' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferPageJobOfferCreatorFragment, unknown>;
export const JobOfferPageAcademicBackgroundFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageAcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferPageAcademicBackgroundFragment, unknown>;
export const InternalManagerWithMyOneToOneChatChannelIdOnlyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  InternalManagerWithMyOneToOneChatChannelIdOnlyFragment,
  unknown
>;
export const JobOfferPageJobOfferInternalManagerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferInternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferPageJobOfferInternalManagerFragment,
  unknown
>;
export const JobOfferPageJobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageAcademicBackground',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageJobOfferInternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithMyOneToOneChatChannelIdOnly' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UserWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageAcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferInternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferPageJobOfferFragment, unknown>;
export const EmployerJobOfferEmployerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobOfferEmployerFragment, unknown>;
export const EmployerJobOfferJobCategoryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferJobCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobOfferJobCategoryFragment, unknown>;
export const EmployerJobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerJobOfferEmployer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerJobOfferJobCategory' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithMyOneToOneChatChannelIdOnly' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UserWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageAcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferInternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageAcademicBackground',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageJobOfferInternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferJobCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerJobOfferFragment, unknown>;
export const RecruiterJobOfferEmployerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterJobOfferEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterJobOfferEmployerFragment, unknown>;
export const RecruiterJobOfferFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecruiterJobOfferEmployer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithMyOneToOneChatChannelIdOnly' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UserWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageAcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferInternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageAcademicBackground',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageJobOfferInternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterJobOfferEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterJobOfferFragment, unknown>;
export const TerminatedJobOfferReturnPayloadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TerminatedJobOfferReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TerminatedJobOfferReturnPayloadFragment, unknown>;
export const CreateProfilePreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateProfilePreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateProfilePreviewFragment, unknown>;
export const LastProfileJobApplicationRecommendationsComputationDetailedFragmentDoc =
  {
    kind: 'Document',
    definitions: [
      {
        kind: 'FragmentDefinition',
        name: {
          kind: 'Name',
          value: 'LastProfileJobApplicationRecommendationsComputationDetailed',
        },
        typeCondition: {
          kind: 'NamedType',
          name: {
            kind: 'Name',
            value: 'ProfileJobApplicationRecommendationsComputation',
          },
        },
        selectionSet: {
          kind: 'SelectionSet',
          selections: [
            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
            { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    LastProfileJobApplicationRecommendationsComputationDetailedFragment,
    unknown
  >;
export const ProfileJobApplicationRecommendationRankingDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileJobApplicationRecommendationRankingDetails',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCode' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumRemuneration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumRemuneration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumVariableRemuneration' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remoteWorkPolicy' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestSuccessFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basisPoints' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferMatchingCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMatching' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileJobApplicationRecommendationRankingDetailsFragment,
  unknown
>;
export const ProfileJobOfferPotentialMatchesDetailsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileJobOfferPotentialMatchesDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileJobOfferPotentialMatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isScoreAccurate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldMatch' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileJobOfferPotentialMatchesDetailsFragment,
  unknown
>;
export const ProfileNoteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProfileNoteFragment, unknown>;
export const EditProfilePersonalNotePreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditProfilePersonalNotePreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileNote' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditProfilePersonalNotePreviewFragment, unknown>;
export const ChatRecruitmentTaskJobApplicationFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatRecruitmentTaskJobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruitmentTask',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChatRecruitmentTaskJobApplicationFragment,
  unknown
>;
export const ChatRecruitmentTaskWithApplicationsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatRecruitmentTaskWithApplications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatRecruitmentTaskJobApplication',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatRecruitmentTaskJobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruitmentTask',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChatRecruitmentTaskWithApplicationsFragment,
  unknown
>;
export const EmployerRecruitmentTaskRecruiterSearchFirmFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerRecruitmentTaskRecruiterSearchFirm',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerRecruitmentTaskRecruiterSearchFirmFragment,
  unknown
>;
export const EmployerRecruitmentTaskRecruiterFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerRecruitmentTaskRecruiter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerRecruitmentTaskRecruiterSearchFirm',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerRecruitmentTaskRecruiterSearchFirm',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerRecruitmentTaskRecruiterFragment, unknown>;
export const EmployerRecruitmentTaskFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerRecruitmentTaskRecruiter',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerRecruitmentTaskRecruiterSearchFirm',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerRecruitmentTaskRecruiter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerRecruitmentTaskRecruiterSearchFirm',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployerRecruitmentTaskFragment, unknown>;
export const JobOfferRecruitmentTaskPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferRecruitmentTaskPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferRecruitmentTaskPreviewFragment, unknown>;
export const CreateSearchFirmReturnPayloadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateSearchFirmReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfRecruiters' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateSearchFirmReturnPayloadFragment, unknown>;
export const RecruiterSettingsSearchFirmFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsSearchFirm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siret' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterSettingsSearchFirmFragment, unknown>;
export const SkillFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Skill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkillFragment, unknown>;
export const SkillWithoutStatusFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillWithoutStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkillWithoutStatusFragment, unknown>;
export const SearchFirmReferenceClientFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchFirmReferenceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SearchFirmReferenceClientFragment, unknown>;
export const RecruiterSeenByEmployerSearchFirmFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSeenByEmployerSearchFirm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceClients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchFirmReferenceClient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchFirmReferenceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSeenByEmployerSearchFirmFragment,
  unknown
>;
export const RecruiterSeenByEmployerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSeenByEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSeenByEmployerSearchFirm',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchFirmReferenceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSeenByEmployerSearchFirm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceClients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchFirmReferenceClient' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterSeenByEmployerFragment, unknown>;
export const RecruiterSettingsCoworkerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsCoworker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'carbonCopyCouplesCreatedByCurrentUser',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecruiterSettingsCoworkerFragment, unknown>;
export const UpdateUserReturnPayloadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateUserReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserReturnPayloadFragment, unknown>;
export const CreateUserLead_UserLeadFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateUserLead_UserLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserLead_UserLeadFragment, unknown>;
export const AcademicBackgroundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'academicBackgrounds' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'AcademicBackground' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcademicBackgroundsQuery,
  AcademicBackgroundsQueryVariables
>;
export const OptionActivitySectorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'optionActivitySectors' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activitySectors' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OptionActivitySector' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OptionActivitySector' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivitySector' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  OptionActivitySectorsQuery,
  OptionActivitySectorsQueryVariables
>;
export const UpdateCarbonCopyCouplesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCarbonCopyCouples' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCarbonCopyCouplesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCarbonCopyCouples' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCarbonCopyCouplesMutation,
  UpdateCarbonCopyCouplesMutationVariables
>;
export const CountriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'countries' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'countries' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CountriesQuery, CountriesQueryVariables>;
export const AcceptJobApplicationForFirstInterviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptJobApplicationForFirstInterview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'AcceptJobApplicationForFirstInterviewInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'acceptJobApplicationForFirstInterview',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'AcceptJobApplicationReturnPayload',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AcceptJobApplicationReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptJobApplicationForFirstInterviewMutation,
  AcceptJobApplicationForFirstInterviewMutationVariables
>;
export const RejectJobApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'rejectJobApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RejectJobApplicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rejectJobApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RejectJobApplicationReturnPayload',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RejectJobApplicationReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RejectJobApplicationMutation,
  RejectJobApplicationMutationVariables
>;
export const ReportJobApplicationAsRejectedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'reportJobApplicationAsRejected' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ReportJobApplicationAsRejectedInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportJobApplicationAsRejected' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ReportJobApplicationAsRejectedReturnPayload',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ReportJobApplicationAsRejectedReturnPayload',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobApplicationWithStatuses' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReportJobApplicationAsRejectedMutation,
  ReportJobApplicationAsRejectedMutationVariables
>;
export const SubmitJobApplicationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'submitJobApplication' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SubmitJobApplicationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'submitJobApplication' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'SubmitJobApplicationReturnPayload',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SubmitJobApplicationReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SubmitJobApplicationMutation,
  SubmitJobApplicationMutationVariables
>;
export const TerminateJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'terminateJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'TerminateJobOfferInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'terminateJobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'TerminatedJobOfferReturnPayload',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TerminatedJobOfferReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TerminateJobOfferMutation,
  TerminateJobOfferMutationVariables
>;
export const EmployerJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employerJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerJobOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithMyOneToOneChatChannelIdOnly' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UserWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageAcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferInternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageAcademicBackground',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageJobOfferInternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferJobCategory' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerJobOfferEmployer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobCategory' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerJobOfferJobCategory' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EmployerJobOfferQuery,
  EmployerJobOfferQueryVariables
>;
export const JobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerDashboardJobOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerDashboardJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfOngoingRecruitmentTasks' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfNewJobApplications' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JobOfferQuery, JobOfferQueryVariables>;
export const JobOfferApplicationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobOfferApplications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobApplicationsFilters' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'JobApplicationsFilters' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferApplications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobApplicationsFilters' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerJobOfferApplication' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobApplicationProfileCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobApplicationProfileCreator',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerJobOfferApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruitmentTask',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationJobOffer' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerJobApplicationProfile',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferApplicationsQuery,
  JobOfferApplicationsQueryVariables
>;
export const RecruiterJobOfferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterJobOffer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecruiterJobOffer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserWithMyOneToOneChatChannelIdOnly' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityMetric' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseTime' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jobApplicationsResponseRate' },
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'UserWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageAcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOfferInternalManager' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'InternalManager' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'profilePictureUrl' } },
          {
            kind: 'FragmentSpread',
            name: {
              kind: 'Name',
              value: 'InternalManagerWithMyOneToOneChatChannelIdOnly',
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'briefingVideoUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'videoAskUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minimumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumRemuneration' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maximumVariableRemuneration' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contractType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'location' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfPositionsToFill' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'recruitmentAdvice' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentProcess' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'careerOutlook' } },
          { kind: 'Field', name: { kind: 'Name', value: 'typicalProfileUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasIncentiveBonus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasLuncheonVouchers' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasHealthInsurancePlan' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'perksDescription' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mandatoryProfileAttributes' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'technicalSkillsDescription' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'isTerminated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'remoteWorkPolicy' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobOfferPageJobOfferCreator' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'academicBackgrounds' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageAcademicBackground',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestSuccessFee' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'basisPoints' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'internalManager' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferPageJobOfferInternalManager',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterJobOfferEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'nonPoachingAgreementsDescription' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterJobOffer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'JobOfferPageJobOffer' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecruiterJobOfferEmployer' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterJobOfferQuery,
  RecruiterJobOfferQueryVariables
>;
export const CreateProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createProfile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreateProfilePreview' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateProfilePreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateProfileMutation,
  CreateProfileMutationVariables
>;
export const EmployersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'employers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetEmployersInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'employers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmployersQuery, EmployersQueryVariables>;
export const ComputeProfileJobApplicationRecommendationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'computeProfileJobApplicationRecommendations',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ComputeProfileJobApplicationRecommendationsInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'computeProfileJobApplicationRecommendations',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ComputeProfileJobApplicationRecommendationsMutation,
  ComputeProfileJobApplicationRecommendationsMutationVariables
>;
export const UpdateProfileAcademicBackgroundsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProfileAcademicBackgrounds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateProfileAcademicBackgroundsInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileAcademicBackgrounds' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'academicBackgrounds' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'AcademicBackground' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AcademicBackground' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'AcademicBackground' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileAcademicBackgroundsMutation,
  UpdateProfileAcademicBackgroundsMutationVariables
>;
export const UpdateProfileSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateProfileSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfileSkillsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateProfileSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'skills' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Skill' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Skill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateProfileSkillsMutation,
  UpdateProfileSkillsMutationVariables
>;
export const LastProfileJobApplicationRecommendationsComputationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'lastProfileJobApplicationRecommendationsComputation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value:
                  'GetLastProfileJobApplicationRecommendationsComputationInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'lastProfileJobApplicationRecommendationsComputation',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value:
                      'LastProfileJobApplicationRecommendationsComputationDetailed',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'LastProfileJobApplicationRecommendationsComputationDetailed',
      },
      typeCondition: {
        kind: 'NamedType',
        name: {
          kind: 'Name',
          value: 'ProfileJobApplicationRecommendationsComputation',
        },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LastProfileJobApplicationRecommendationsComputationQuery,
  LastProfileJobApplicationRecommendationsComputationQueryVariables
>;
export const ProfileJobApplicationRecommendationRankingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'profileJobApplicationRecommendationRanking',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'GetProfileJobApplicationRecommendationRankingInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'profileJobApplicationRecommendationRanking',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileJobApplicationRecommendationRankingDetails',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProfileJobApplicationRecommendationRankingDetails',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationRecommendation' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterSuccessFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiterFlatFeeInBasisPoints' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currencyCode' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minimumRemuneration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumRemuneration' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maximumVariableRemuneration' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'location' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'minNumberOfYearsOfExperience' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'maxNumberOfYearsOfExperience' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remoteWorkPolicy' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestSuccessFee' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'basisPoints' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferMatchingCriteria' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isMatching' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileJobApplicationRecommendationRankingQuery,
  ProfileJobApplicationRecommendationRankingQueryVariables
>;
export const ProfileJobApplicationRecommendationsComputationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: {
        kind: 'Name',
        value: 'profileJobApplicationRecommendationsComputation',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value:
                  'GetProfileJobApplicationRecommendationsComputationInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'profileJobApplicationRecommendationsComputation',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileJobApplicationRecommendationsComputationQuery,
  ProfileJobApplicationRecommendationsComputationQueryVariables
>;
export const ComputeProfileJobOfferPotentialMatchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'computeProfileJobOfferPotentialMatches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ComputeProfileJobOfferPotentialMatchesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'computeProfileJobOfferPotentialMatches',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ComputeProfileJobOfferPotentialMatchesMutation,
  ComputeProfileJobOfferPotentialMatchesMutationVariables
>;
export const AdminPanelProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'adminPanelProfile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AdminPanelProfileQuery,
  AdminPanelProfileQueryVariables
>;
export const ProfileJobOfferPotentialMatchesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'profileJobOfferPotentialMatches' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'GetProfileJobOfferPotentialMatchesInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profileJobOfferPotentialMatches' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProfileJobOfferPotentialMatchesDetails',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileJobOfferPotentialMatchesDetails' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileJobOfferPotentialMatch' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isScoreAccurate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'score' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shouldMatch' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ProfileJobOfferPotentialMatchesQuery,
  ProfileJobOfferPotentialMatchesQueryVariables
>;
export const EditProfilePersonalNoteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'editProfilePersonalNote' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'EditProfilePersonalNoteInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editProfilePersonalNote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EditProfilePersonalNotePreview',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProfileNote' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProfileNote' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EditProfilePersonalNotePreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myNote' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProfileNote' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EditProfilePersonalNoteMutation,
  EditProfilePersonalNoteMutationVariables
>;
export const AbandonRecruitmentTaskDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'abandonRecruitmentTask' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AbandonRecruitmentTaskInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'abandonRecruitmentTask' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AbandonRecruitmentTaskMutation,
  AbandonRecruitmentTaskMutationVariables
>;
export const JobOfferRecruitmentTaskPreviewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobOfferRecruitmentTaskPreviews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferRecruitmentTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobOfferRecruitmentTaskPreview',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobOfferRecruitmentTaskPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTaskPreviewsQuery,
  JobOfferRecruitmentTaskPreviewsQueryVariables
>;
export const JobOfferRecruitmentTasksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jobOfferRecruitmentTasks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobOfferId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOfferRecruitmentTasks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobOfferId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobOfferId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EmployerRecruitmentTask' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseUser' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'EmployerRecruitmentTaskRecruiterSearchFirm',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerRecruitmentTaskRecruiter' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BaseUser' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'myOneToOneChatChannelId' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerRecruitmentTaskRecruiterSearchFirm',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EmployerRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'EmployerRecruitmentTaskRecruiter',
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  JobOfferRecruitmentTasksQuery,
  JobOfferRecruitmentTasksQueryVariables
>;
export const RecruitmentTasksOfRecruiterForMyEmployerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruitmentTasksOfRecruiterForMyEmployer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'RecruitmentTasksOfRecruiterForMyEmployerInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'recruitmentTasksOfRecruiterForMyEmployer',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatRecruitmentTaskWithApplications',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Message' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'body' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'creator' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplicationStatus' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'message' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatusMessage' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Profile' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkedInProfileUrl' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curriculumVitaeUrl' },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'JobApplicationRecruitmentTask' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatRecruitmentTaskJobApplication' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'JobApplication' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statuses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'JobApplicationStatus' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'BaseJobApplicationProfile' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'creator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'phoneNumber' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruitmentTask' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'JobApplicationRecruitmentTask',
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ChatRecruitmentTaskWithApplications' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'RecruitmentTask' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobOffer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jobApplications' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ChatRecruitmentTaskJobApplication',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruitmentTasksOfRecruiterForMyEmployerQuery,
  RecruitmentTasksOfRecruiterForMyEmployerQueryVariables
>;
export const AcceptRecruitmentTaskRecommendationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptRecruitmentTaskRecommendation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recommendationId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'acceptRecruitmentTaskRecommendation',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recommendationId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recommendationId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptRecruitmentTaskRecommendationMutation,
  AcceptRecruitmentTaskRecommendationMutationVariables
>;
export const CreateSearchFirmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSearchFirm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateSearchFirmInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSearchFirm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'CreateSearchFirmReturnPayload',
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateSearchFirmReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numberOfRecruiters' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legalForm' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSearchFirmMutation,
  CreateSearchFirmMutationVariables
>;
export const GenerateSearchFirmContractDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateSearchFirmContract' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GenerateSearchFirmContractInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateSearchFirmContract' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateSearchFirmContractMutation,
  GenerateSearchFirmContractMutationVariables
>;
export const UpdateSearchFirmDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateSearchFirm' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateSearchFirmInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSearchFirm' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecruiterSettingsSearchFirm' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsSearchFirm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'siret' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'address' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'streetAddress' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'locality' } },
                { kind: 'Field', name: { kind: 'Name', value: 'postalCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bankDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'iban' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateSearchFirmMutation,
  UpdateSearchFirmMutationVariables
>;
export const CheckIfSearchFirmNameIsAvailableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'checkIfSearchFirmNameIsAvailable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'CheckIfSearchFirmNameIsAvailableInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkIfSearchFirmNameIsAvailable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIfSearchFirmNameIsAvailableQuery,
  CheckIfSearchFirmNameIsAvailableQueryVariables
>;
export const SkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'skills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetSkillsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'skills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Skill' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Skill' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SkillsQuery, SkillsQueryVariables>;
export const ValidSkillsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'validSkills' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GetValidSkillsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'validSkills' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SkillWithoutStatus' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SkillWithoutStatus' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Skill' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ValidSkillsQuery, ValidSkillsQueryVariables>;
export const GetSupportChatUserIdentifierDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSupportChatUserIdentifier' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supportChatUserIdentifier' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSupportChatUserIdentifierQuery,
  GetSupportChatUserIdentifierQueryVariables
>;
export const UrbanAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'urbanAreas' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'urbanAreas' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UrbanAreasQuery, UrbanAreasQueryVariables>;
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateUserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UpdateUserReturnPayload' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UpdateUserReturnPayload' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const RecruiterOnboardingUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterOnboardingUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'me' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterOnboardingUserQuery,
  RecruiterOnboardingUserQueryVariables
>;
export const RecruiterSeenByEmployerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterSeenByEmployer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'recruiter' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecruiterSeenByEmployer' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SearchFirmReferenceClient' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Employer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logoUrl' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSeenByEmployerSearchFirm' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchFirm' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'referenceClients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'SearchFirmReferenceClient' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSeenByEmployer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchFirm' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'RecruiterSeenByEmployerSearchFirm',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSeenByEmployerQuery,
  RecruiterSeenByEmployerQueryVariables
>;
export const RecruiterSettingsCoworkersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'recruiterSettingsCoworkers' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coworkers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'RecruiterSettingsCoworker' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'RecruiterSettingsCoworker' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'carbonCopyCouplesCreatedByCurrentUser',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'topic' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RecruiterSettingsCoworkersQuery,
  RecruiterSettingsCoworkersQueryVariables
>;
export const UpdateRecruiterActivitySectorSpecializationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: {
        kind: 'Name',
        value: 'updateRecruiterActivitySectorSpecializations',
      },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'UpdateRecruiterActivitySectorSpecializationsInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'updateRecruiterActivitySectorSpecializations',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateRecruiterActivitySectorSpecializationsMutation,
  UpdateRecruiterActivitySectorSpecializationsMutationVariables
>;
export const CreateUserLeadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createUserLead' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateUserLeadInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUserLead' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'CreateUserLead_UserLead' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CreateUserLead_UserLead' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserLead' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mainBusinessType' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateUserLeadMutation,
  CreateUserLeadMutationVariables
>;
