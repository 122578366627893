import { ApolloProvider } from '@apollo/client';
import { getApolloClient } from '@core/clients/apollo/client';
import { UrgentBanner } from '@core/components/Banners/UrgentBanner.component';
import { yupStandardErrorMessages } from '@core/components/Form/utils/yupStandardErrorMessages';
import { ScreenSizeContextProvider } from '@core/components/ScreenSizeContext/ScreenSizeContextProvider.component';
import { ConsoleAnalyticService } from 'modules/Analytics/clients/ConsoleAnalyticService';
import { HubspotAnalyticService } from 'modules/Analytics/clients/HubspotAnalyticService';
import { PosthogAnalyticService } from 'modules/Analytics/clients/PosthogAnalyticService';
import { SentryAnalyticService } from 'modules/Analytics/clients/SentryAnalyticService';
import { AnalyticsServiceProvider } from 'modules/Analytics/components/AnalyticsServiceProvider.component';
import { CompositeAnalyticService } from 'modules/Analytics/CompositeAnalyticService';
import { getAuthService } from 'modules/Authentication/clients/getAuthService';
import { AuthenticationServiceProvider } from 'modules/Authentication/context/AuthenticationServiceProvider';
import { ChatProvider } from 'modules/Chat/context/ChatProvider';
import { MyUserProvider } from 'modules/MyUser/context/MyUserProvider';
import { PostHogProvider } from 'modules/PostHog/PostHogProvider';
import { PusherProvider } from 'modules/Pusher/PusherProvider';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { FunctionComponent } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'src/styles/global.css';
import 'src/styles/tailwind.css';
import * as yup from 'yup';

interface Props extends AppProps {
  err: Error;
  Component: FunctionComponent;
}

const analyticService = new CompositeAnalyticService([
  new PosthogAnalyticService(),
  new HubspotAnalyticService(),
  new SentryAnalyticService(),
  new ConsoleAnalyticService(process.env.NODE_ENV === 'development'),
]);

const authenticationService = getAuthService({ analyticService });
const apolloClient = getApolloClient({
  authenticationService,
  analyticService,
});

const faviconBasePath = `/favicon/${process.env.NEXT_PUBLIC_ENV}`;

yup.setLocale(yupStandardErrorMessages);

const App: FunctionComponent<Props> = ({ Component, pageProps, err }) => {
  return (
    <>
      <Head>
        <title>Muzzo</title>
        {/* favicons generated from https://realfavicongenerator.net/favicon/nextjs */}
        <link
          rel="icon"
          type="image/png"
          href={`${faviconBasePath}/favicon-48x48.png`}
          sizes="48x48"
        />
        <link
          rel="icon"
          type="image/svg+xml"
          href={`${faviconBasePath}/favicon.svg`}
        />
        <link rel="shortcut icon" href={`${faviconBasePath}/favicon.ico`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${faviconBasePath}/apple-touch-icon.png`}
        />
        <meta name="apple-mobile-web-app-title" content="Muzzo" />
        <link rel="manifest" href={`${faviconBasePath}/site.webmanifest`} />
        <meta
          name="viewport"
          content="initial-scale=1.0, maximum-scale=1, width=device-width"
        />
      </Head>
      <ScreenSizeContextProvider>
        <AuthenticationServiceProvider
          authenticationService={authenticationService}
        >
          <ApolloProvider client={apolloClient}>
            <MyUserProvider>
              <PostHogProvider>
                <AnalyticsServiceProvider analyticService={analyticService}>
                  <PusherProvider>
                    <ChatProvider>
                      {/* hide UrgentBanner as long as there is no problem online.
        If there is a need for the urgent banner, don't forget to put some understandable text inside. */}
                      <div className="hidden">
                        <UrgentBanner />
                      </div>

                      <Component {...pageProps} err={err} />
                      <ToastContainer
                        newestOnTop={true}
                        className="sm:w-auto sm:max-w-100"
                      />
                    </ChatProvider>
                  </PusherProvider>
                </AnalyticsServiceProvider>
              </PostHogProvider>
            </MyUserProvider>
          </ApolloProvider>
        </AuthenticationServiceProvider>
      </ScreenSizeContextProvider>
    </>
  );
};

export default App;
