import { FieldMergeFunction } from '@apollo/client';
import { stripUrlQueryParams } from '@core/utils/urlQueryParams/stripUrlQueryParams.utils';
import { User } from 'graphql/generated/graphql';

/**
 * We don't want to override the incoming profilePictureUrl in the cache if the image is the same.
 * The API returns a presigned url that always change, even if it is the same image.
 * If we override the cache with the incoming one, components that require this field (such as the employer job applications table) re-render because the profilePictureUrl has changed and the same image is refetched, causing a glitchy UI
 *
 * NB: We tried to completely ignore this field by returning "undefined" but it did not work because it will instruct Apollo Client to fetch the queries that require this field from the server and the other custom cache functions will be bypassed
 */
export const mergeIncomingProfilePictureUrlIntoCache: FieldMergeFunction<
  User['profilePictureUrl'],
  Exclude<User['profilePictureUrl'], undefined>
> = (existing, incoming): User['profilePictureUrl'] => {
  if (!existing || !incoming) {
    return incoming;
  }

  if (!checkIfUrlIsValid(incoming)) {
    return existing;
  }

  const areProfilePictureBaseUrlsEqual =
    stripUrlQueryParams(existing) === stripUrlQueryParams(incoming);

  return areProfilePictureBaseUrlsEqual ? existing : incoming;
};

const checkIfUrlIsValid = (maybeUrl: string) => {
  try {
    new URL(maybeUrl);
    return true;
  } catch (err) {
    return false;
  }
};
